import countryList from 'country-list';
import React, { ReactElement, useMemo, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { Button, Icon, IconSizes, ListRadioOptions, RadioOption } from '@musiversal/musiversal-components';
import imageUrl from '../../../../../assets/images/paywall-modal/ticket-paywall.png';
import { editedCountryNames } from '../../../../constants/country-list-rename.const';
import { PORTUGUESE_TAX } from '../../../../constants/european-tax.const';
import { EuropeanTaxableCountriesEnum } from '../../../../constants/european-taxable-countries.const';
import { ModalTypes } from '../../../../enums/modal-types.enum';
import { PaywallTypes } from '../../../../enums/paywall-types.enum';
import { PaymentProviders } from '../../../../enums/payment-providers.enum';
import { getPlanPriceIdByEnvHelper } from '../../../../helpers/get-plan-price-id-by-env.helper';
import { ModalInterface } from '../../../../interfaces/modal.interface';
import UserService from '../../../../services/user/user.service';
import PaypalService from '../../../../services/subscriptions/paypal.service';
import StripeService from '../../../../services/subscriptions/stripe.service';
import { getPlans } from '../../../../store/actions/global.actions';
import { selectPlans, setIsShowGlobalLoader } from '../../../../store/slices/global.slice';
import { setIsShowPaywallModal, setIsShowBillingAddressModal } from '../../../../store/slices/modals.slice';
import { selectUser } from '../../../../store/slices/user.slice';

import RadioWithIcon from '../../../Atoms/v3/RadioWithIcon/RadioWithIcon';
import VATBreakdown from '../../../Atoms/v3/VATBreakdown/VATBreakdown';
import ModalWrapper from '../../ModalWrapper/ModalWrapper';
import { showPaymentMethodModalStatuses } from '../../PlansModal/PlansModal';
import styles from './PaywallModal.module.scss';

countryList.overwrite(editedCountryNames); // needed to refactor some of country names, that were too long or too formal
const countries = countryList.getData();

countries.sort((a, b) => {
  if (a.name === 'United States' || a.name === 'Canada' || a.name === 'United Kingdom') {
    return -1;
  } else if (b.name === 'United States' || b.name === 'Canada' || b.name === 'United Kingdom') {
    return 1;
  } else if (a.name < b.name) {
    return -1;
  }
  return 0;
});

let stripePromise = null;
if (process.env.REACT_APP_STRIPE_ID) {
  import('@stripe/stripe-js').then(({ loadStripe }) => (stripePromise = loadStripe(process.env.REACT_APP_STRIPE_ID)));
}

const PaywallModal = ({ isOpen }: ModalInterface): ReactElement => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser, shallowEqual);
  const plans = useSelector(selectPlans, shallowEqual);
  const [unlimitedPlan, setUnlimitedPlan] = useState(null);
  const [skippable, setSkippable] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState(PaymentProviders.STRIPE);
  const [inviteExpirationDate, setInviteExpirationDate] = useState(null);

  useEffect(() => {
    if (plans && plans?.length >= 0 && plans[0]?.memberships?.length) {
      return;
    }
    // if (user.status !== 'staff' && user.status === 'waitlister' && user.status === 'invited') {
    dispatch(getPlans(user));
    // }
  }, [isOpen]);

  useEffect(() => {
    setSkippable(user && user?.paywall !== PaywallTypes.ALWAYS);
  }, [user]);

  useEffect(() => {
    setUnlimitedPlan(plans?.length > 0 ? plans[0] : null);
  }, [plans]);

  useEffect(() => {
    const fetchWaitlistStatus = async () => {
      try {
        const res = await UserService.getWaitlistStatus();
        if (res?.status === 200) {
          const { data } = res;
          setInviteExpirationDate(data?.invite_expiration_date);
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchWaitlistStatus();
  }, []);

  const firstName = useMemo(() => user?.name?.split(' ')[0] ?? '', [user]);
  const { topup_price: topupPrice, country: userCountry } = user;

  const {
    handleSubmit,
    control,
    getValues,
    watch,
    formState: { errors },
    clearErrors,
    register,
    reset
  } = useForm({ paymentsProvider: PaymentProviders.STRIPE });

  const ppWatch = watch('paymentsProvider');

  // const setLocalStorageCurrentPlanId = () => localStorage.setItem(localStorageKeys.CURRENT_PLAN_ID, unlimitedPlan);

  const onRequestClose = (): void => {
    dispatch(setIsShowPaywallModal(false));
  };

  const openEditAddress = (): void => {
    dispatch(setIsShowPaywallModal(false));
    dispatch(setIsShowBillingAddressModal(true));
  };

  // const showVAT = EuropeanTaxableCountriesEnum.includes(userCountry) ?? false;

  const applyTax = (): void => {
    if (EuropeanTaxableCountriesEnum.includes(userCountry)) {
      return 1 + PORTUGUESE_TAX / 100;
    }
    return 1;
  };

  // const total = (): ReactElement => {
  //   const crossedOut = unlimitedPlan?.discounted ? (
  //     <span className={styles.crossedOut}>${(unlimitedPlan?.old_price * applyTax()).toFixed(2)}</span>
  //   ) : (
  //     ''
  //   );
  //   return (
  //     <span className={styles.totalPrice}>
  //       Pay {crossedOut} ${((unlimitedPlan?.price ?? 0) * applyTax()).toFixed(2)} per month
  //     </span>
  //   );
  // };

  const redirectToPlanUrl = async planId => {
    dispatch(setIsShowGlobalLoader(true));
    try {
      const res = await StripeService.createCheckoutSession(planId);
      if (res.status === 200) {
        const stripe = await stripePromise;

        const { sessionId } = res.data;
        // setLocalStorageCurrentPlanId();
        stripe
          .redirectToCheckout({
            sessionId
          })
          .finally();
      }
    } catch (err) {
      console.error(err);
      dispatch(setIsShowGlobalLoader(false));
    }
  };

  const onSubscribeClick = async (): Promise<void> => {
    dispatch(setIsShowGlobalLoader(true));
    // Process request
    const data = getValues();
    let { paymentsProvider } = getValues();
    if (!paymentsProvider) {
      paymentsProvider = PaymentProviders.STRIPE;
    }
    try {
      const priceId = getPlanPriceIdByEnvHelper(unlimitedPlan, paymentsProvider);
      if (paymentsProvider === PaymentProviders.PAYPAL) {
        dispatch(setIsShowGlobalLoader(true));
        const { url } = (await PaypalService.createPaypalSubscription(priceId)).data;
        window.location.href = url;
        dispatch(setIsShowGlobalLoader(false));
        return;
      }
      await redirectToPlanUrl(priceId);
      // onRequestClose();
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setIsShowGlobalLoader(false));
    }
  };

  const paymentOptions = [
    {
      id: PaymentProviders.STRIPE,
      value: PaymentProviders.STRIPE,
      label: 'Credit Card',
      icon: 'credit_card'
    },
    {
      id: PaymentProviders.PAYPAL,
      value: PaymentProviders.PAYPAL,
      label: 'Paypal',
      icon: PaymentProviders.PAYPAL
    }
  ];

  return (
    <ModalWrapper
      isPreventClosing={!skippable}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      modalType={ModalTypes.DEFAULT}
      className={styles.modal}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.image}>
            <img src={imageUrl} alt='Paywall ticket image' style={{ maxWidth: '100%', maxHeight: '100%' }} />
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.title}>Mics are up, {firstName}!</div>
          <div className={styles.subtitle}>
            <p>{"Choose how you'd like to pay and let's hit the studio!"}</p>
            <p>{`Don't delay, your invitation expires on ${moment(inviteExpirationDate).format('MMM DD')}.`}</p>
          </div>
        </div>
        <div className={styles.paymentOptions}>
          {paymentOptions.map(({ id, label, value, icon }) => (
              <RadioOption
                required
                name='paymentsProvider'
                control={control}
                key={id}
                id={id}
                value={value}
                label={label}
                icon={icon}
                defaultChecked={value === paymentMethod}
                labelClassName={styles.reason}
                onClick={() => setPaymentMethod(value)}
              />
          ))}
        </div>
        {/* {showVAT && (
          <div className={styles.VATBreakdown}>
            <VATBreakdown amount={unlimitedPlan?.price} />
          </div>
        )} */}
          <Button onClick={onSubscribeClick} width={'100%'} buttonText={"Let's Make Music!"} />
        <div className={styles.secureCheckout}>
          Billing Country: <a className={styles.openBillingModalLink} onClick={openEditAddress}>{countries.find(({ code }) => code === userCountry)?.name}</a>
          <Icon name='lock' className={styles.lockIcon} size={IconSizes.SMALL} /> Secure checkout
        </div>
      </div>

    </ModalWrapper>
  );
};

export default PaywallModal;
