import axios, { AxiosPromise } from 'axios';

import { UserInterface } from '../../interfaces/user.interface';
import {
  ChangePasswordDataInterface,
  SendOnboardingDataInterface,
  FinishAccountSetupDataInterface,
  UpdateUserInterface,
  UpdateBillingAddressInterface,
  UpdatePhoneNumberInterface,
  SimpleResponseInterface,
  WaitlistResponseInterface
} from './user.interface';

const USERS_API = `${process.env.REACT_APP_API_URL}/api/users`;

const BASE_API = `${process.env.REACT_APP_API_URL}/api/v2`;

export const MESSAGES_PAGE_SIZE = 1000;

const axiosBaseInstance = axios.create({
  baseURL: BASE_API,
  withCredentials: true
});

const axiosInstance = axios.create({
  baseURL: USERS_API,
  withCredentials: true
});

const getMeRequest = (): AxiosPromise<{ user: UserInterface }> => axiosBaseInstance.get('/users/me');

const updateBillingAddress = (payload: UpdateBillingAddressInterface): AxiosPromise<{ user: UserInterface }> =>
  axiosBaseInstance.put('/users/billing-information', payload);

const updateMe = (user: UpdateUserInterface): AxiosPromise<{ user: UserInterface }> => axiosInstance.put('/me', user);

const updatePhoneNumber = (payload: UpdatePhoneNumberInterface): AxiosPromise<{ data: SimpleResponseInterface }> => axiosBaseInstance.put('/users/update-phone', payload);

const getWaitlistStatus = (): AxiosPromise<{ data: WaitlistResponseInterface }> => axiosBaseInstance.get('/waitlist');

const uploadAvatar = (image: FormData): AxiosPromise => axiosInstance.post('/avatar', image);

const changePassword = (changePasswordData: ChangePasswordDataInterface): AxiosPromise =>
  axiosInstance.post('/password', changePasswordData);

const sendOnboardingData = (data: SendOnboardingDataInterface): AxiosPromise =>
  axiosBaseInstance.post('/onboarding', data);

const finishAccountSetup = (data: FinishAccountSetupDataInterface): AxiosPromise =>
  axiosBaseInstance.post('/onboarding/finish-account-setup', data);

const completeOnboarding = (data: any): AxiosPromise =>
  axiosBaseInstance.post('/onboarding/complete', data);

const getPersona = (data: any): AxiosPromise =>
  axiosBaseInstance.get('/onboarding/persona', data);

const rejoinWaitlistNotification = (data: any): AxiosPromise =>
axiosBaseInstance.post('/waitlist/rejoin-waitlist', data);

export default {
  getMeRequest,
  uploadAvatar,
  updateMe,
  updatePhoneNumber,
  changePassword,
  sendOnboardingData,
  finishAccountSetup,
  completeOnboarding,
  rejoinWaitlistNotification,
  updateBillingAddress,
  getWaitlistStatus,
  getPersona
};
