import React, { ReactElement } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { RootState } from '../../../store/store';
import BillingAddress from '../../Modals/BillingAddressModal/BillingAddressModal';
import BlockingModal from '../../Modals/BlockingModal/BlockingModal';
import HowItWorksModal from '../../Modals/HowItWorksModal/HowItWorksModal';
import OffboardingModal from '../../Modals/OffboardingModal/OffboardingModal';
import PlanSelectionModal from '../../Modals/PlanSelectionModal/PlanSelectionModal';
import PlansModal from '../../Modals/PlansModal/PlansModal';
import SpeedLimitModal from '../../Modals/v3/SpeedLimitModal/SpeedLimitModal';
import TimezoneModal from '../../Modals/TimezoneModal/TimezoneModal';
import TopUpModal from '../../Modals/TopUpModal/TopUpModal';
import CancelSessionModal from '../../Modals/CancelSessionModal/CancelSessionModal';
import RatingModal from '../../Modals/RatingModal/RatingModal';
import UpsellPlansModal from '../../Modals/UpsellPlansModal/UpsellPlansModal';
import SelectionModal from '../../Modals/v3/SelectionModal/SelectionModal';
import VideoModal from '../../Modals/v3/VideoModal/VideoModal';
import HelpModal from '../../Modals/v3/HelpModal/HelpModal';
import HireProducerModal from '../../Modals/v3/HireProducerModal/HireProducerModal';
import SubscriptionModal from '../../Modals/v3/SubscriptionModal/SubscriptionModal';
import RejoinWaitlistModal from '../../Modals/v3/RejoinWaitlistModal/RejoinWaitlistModal';
import WaitlistModal from '../../Modals/v3/WaitlistModal/WaitlistModal';
import PaywallModal from '../../Modals/v3/PaywallModal/PaywallModal';
import InvitationExpiredModal from '../../Modals/v3/InvitationExpiredModal/InvitationExpiredModal';
import OnboardingModal from '../../Modals/v3/OnboardingModal/OnboardingModal';
import PaypalConfirmationModal from 'app/components/Modals/v3/PaypalConfirmationModal/PaypalConfirmationModal';

const ModalsContainer = (): ReactElement => {
  const {
    isShowBillingModal,
    isShowSpeedLimitModal,
    isShowTimezoneModal,
    isShowOffboardingModal,
    isSelectionModal,
    isHelpModal,
    isVideoModal,
    isHireProducerModal,
    isRejoinWaitlistModal,
    isShowHowItWorksModal,
    isShowBlockingModal,
    isShowBillingAddressModal,
    isShowRatingModal,
    isShowTopUpModal,
    isShowSubscriptionModal,
    isShowCancelSessionModal,
    isShowPlansModal,
    isUpsellPlansModal,
    isWaitlistModal,
    isShowPaywallModal,
    isShowInvitationExpiredModal,
    isShowOnboardingModal,
    isShowPaypalConfirmationModal
  } = useSelector((state: RootState) => state.modalsSlice, shallowEqual);

  return (
    <>
      <PlanSelectionModal isOpen={isShowBillingModal} />
      <SpeedLimitModal isOpen={isShowSpeedLimitModal} />
      <TimezoneModal isOpen={isShowTimezoneModal} />
      <OffboardingModal isOpen={isShowOffboardingModal} />
      <SelectionModal isOpen={isSelectionModal} />
      <HelpModal isOpen={isHelpModal} />
      <VideoModal isOpen={isVideoModal} />
      <HireProducerModal isOpen={isHireProducerModal} />
      <RejoinWaitlistModal isOpen={isRejoinWaitlistModal} />
      <SubscriptionModal isOpen={isShowSubscriptionModal} />
      <BlockingModal isOpen={isShowBlockingModal} />
      <HowItWorksModal isOpen={isShowHowItWorksModal} />
      <TopUpModal isOpen={isShowTopUpModal.isShown} />
      <CancelSessionModal isOpen={isShowCancelSessionModal.isShown} />
      <PlansModal isOpen={isShowPlansModal} />
      <UpsellPlansModal isOpen={isUpsellPlansModal} />
      <BillingAddress isOpen={isShowBillingAddressModal} />
      <RatingModal isOpen={isShowRatingModal.isShown} />
      <WaitlistModal isOpen={isWaitlistModal} />
      <PaywallModal isOpen={isShowPaywallModal} />
      <InvitationExpiredModal isOpen={isShowInvitationExpiredModal} />
      <OnboardingModal isOpen={isShowOnboardingModal} />
      <PaypalConfirmationModal isOpen={isShowPaypalConfirmationModal} />
    </>
  );
};

export default ModalsContainer;
