import React, { useMemo } from 'react';

import InputError from '../../../../Atoms/InputError/InputError';
import styles from '../../BookSessionForm.module.scss';
import { shallowEqual, useSelector } from 'react-redux';
import { selectSession } from '../../../../../store/slices/sessions.slice';
import CustomSelect from '../../../../Atoms/Select/Select';
import { selectCurrentlyBookingArtistConfig } from '../../../../../store/slices/musicians.slice';
import Warning from '../../../../Atoms/Warning/Warning';
import { ServiceType } from '../../../../../enums/service-type';

export default function DeliverablesForm({
                                           extraConfig,
                                           children,
                                           isShowSendAMessage,
                                           control,
                                           register,
                                           errors,
                                           clearErrors,
                                           getValues
                                         }) {
  const session = useSelector(selectSession, shallowEqual);
  const artistConfig = useSelector(selectCurrentlyBookingArtistConfig, shallowEqual);
  const selectedService = (artistConfig.services.servicesList.find(({ value }) =>
    value === getValues().service || value === session?.session_info?.options) || {});
  const formToggles = selectedService.form;

  const instruments = artistConfig.services?.servicesList;

  const isOnlyOffline = session?.partner?.sessions_type === 'offline';
  const attendanceOptions = isOnlyOffline
    ? [{ message: 'No (offline session)', value: 'false' }]
    : [
      { message: 'Yes (recommended)', value: 'true' },
      { message: 'No', value: 'false' }
    ];

  /* Transforms this object:
  {
    defaultOption: 'Stems to be imported in a different DAW',
    deliverableOption: [
      {id: 1, label: 'Stems to be imported in a different DAW', value: 'Stems to be imported in a different DAW'},
      {id: 2, label: 'Logic session', value: 'Logic session'}
    ]
  }
  into this object:
  {
    default: 'Stems to be imported in a different DAW',
    options: ['Stems to be imported in a different DAW', 'Logic session']
  }
  */
  // const transformDeliverablesOptions = deliverableOptions => {
  //   const { defaultOption, deliverableOption } = deliverableOptions;
  //   const r = {
  //     default: defaultOption,
  //     options: deliverableOption.map(option => option.value)
  //   };
  //   console.log(r);
  //   return r;
  // };

  const transformedDeliverablesOptions =  useMemo((): any => {
    const { defaultOption, deliverableOption } = artistConfig.deliverables;
    return {
      default: defaultOption,
      options: deliverableOption.map(option => option.value)
    };
  }, [artistConfig.deliverables]);

  return (
    <>
      {(formToggles.warning ?? true) && <Warning>
        <p>{formToggles.warning_message}</p>
      </Warning>}
      {/*TODO - will be replaced with component list feature*/}
      {/* {!artistConfig.services_first && <CustomSelect
        control={control}
        label={artistConfig.services?.label}
        name={'extra'}
        options={instruments}
        containerClassName={styles.customSelect}
        labelClassName={styles.label}
        className={styles.control}
      />} */}
      {(formToggles.deliverables ?? true) && <>
        <h4 className={styles.sessionForm__subTitle}>How do you want to receive your files?</h4>
        {console.log(transformedDeliverablesOptions)}
        {transformedDeliverablesOptions.options?.map((option, index) => (
          <div key={index} className={styles.sessionForm__radioInput}>
            <input
              type='radio'
              id={`deliverables_${index}`}
              name='files_format'
              value={option}
              defaultChecked={transformedDeliverablesOptions.default === option}
              onClick={() => clearErrors()}
              {...register('files_format', {
                required: 'Required'
              })}
            />
            <label htmlFor={`deliverables_${index}`}>{option}</label>
          </div>
        ))}
        {errors.files_format && <InputError message={errors.files_format.message} />}
      </>}
      {selectedService.session_type === ServiceType.ALL && <>
        <h4 className={styles.sessionForm__subTitle + ' ' + styles.sessionForm__subTitleSpaceTop}>Will you be attending
          the live streaming of the session?</h4>
        {attendanceOptions.map((option, index) => (
          <div key={`att-${index}`} className={styles.sessionForm__radioInput}>
            <input
              type='radio'
              id={`attendance_${index}`}
              name='attendance'
              data-id={`attendance_${index}`}
              value={option.value}
              onClick={() => clearErrors()}
              {...register('attendance', {
                required: 'Required'
              })}
            />
            <label htmlFor={`attendance_${index}`}>{option.message}</label>
          </div>
        ))}
      </>}
      {isShowSendAMessage && <>
        <h4 className={styles.sessionForm__subTitle + ' ' + styles.sessionForm__subTitleSpaceTop}>Send a message</h4>
        <label className={styles.sessionForm__notes}>
            <textarea
              className={styles.input}
              id='message'
              name='message'
              type='textarea'
              placeholder='Please provide any additional details or instructions.'
              onClick={() => clearErrors()}
              {...register('message')}
            />
          {errors.message && <InputError message={errors.message?.message} />}
        </label>
      </>}
      {errors.server && <InputError message={errors.server.message} />}
      {children}
    </>
  );
}
