export enum Routes {
  PROFILE = '/profile',
  BILLING = '/profile#billing',
  REFERRAL = '/referral',
  SIGNUP = '/signup',
  LOGIN = '/login',
  JOIN_WAITLIST = '/join-waitlist',
  PAYPAL_CONFIRMATION = '/paypal-confirmation',
  WELCOME = '/welcome'
}
