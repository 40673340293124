import { SplitContainer, ListItems } from '@musiversal/musiversal-components';
import cookieCutter from 'cookie-cutter';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment-timezone';
import * as queryString from 'query-string';
import React, { ReactElement, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useForm } from 'react-hook-form';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import XRegExp from 'xregexp';

import Icon from '../../components/Atoms/Icon/Icon';
import Button from '../../components/Atoms/v3/Button/Button';
import FacebookLogin from '../../components/Atoms/v3/FacebookLogin/FacebookLogin';
import GoogleLoginButton from '../../components/Atoms/v3/GoogleLoginButton/GoogleLoginButton';
import Input from '../../components/Atoms/v3/Input/Input';
import TrustedBy from '../../components/Atoms/v3/TrustedBy/TrustedBy';
import SignUpFlowNavbar from '../../components/Molecules/SignUpFlowNavbar/SignUpFlowNavbar';
import { loginText } from '../../components/Molecules/SignUpFlowNavbar/SignUpFlowNavbar.const';
import { ButtonSizes } from '../../enums/button-sizes.enum';
import { InputTypes } from '../../enums/input-types.enum';
import { MixPanelSignUpEvents } from '../../enums/mixpanel-events.enum';
import { MixPanelChoseSignUpMethodProperties } from '../../enums/mixpanel-properties.enum';
import { Routes } from '../../enums/routes.enum';
import { SignupMethods } from '../../enums/signup-methods.enum';
import { SignupSteps } from '../../enums/signup-steps.enum';
import useIsPartnerGuard from '../../guards/useIsPartnerDashboard.guard';
import { Mixpanel } from '../../helpers/mixpanel.helper';
import { SignupDataInterface } from '../../services/auth/auth.interface';
import AuthService from '../../services/auth/auth.service';
import { getOnboardingData } from '../../store/actions/global.actions';
import { getMe } from '../../store/actions/user.actions';
import { selectOnboardingData } from '../../store/slices/global.slice';
import styles from './Signup.module.scss';
import ReferralWelcome from './components/referral-welcome/referral-welcome';
import {
  buttonSubmittingText,
  buttonText,
  orText,
  referralErrorSubheader,
  referralHeader,
  referralUsedSubheader,
  signUpHeader,
  rightColumnTitle
} from './signup.const';
import { useCheckCodeHook } from './useCheckCode.hook';

const Signup = (): ReactElement => {
  const { loginWithFacebook, loginWithGoogle, signupFlowCcFirst, studioTrialsOnSignup } = useFlags();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    watch,
    setValue
  } = useForm();
  const [submitting, setSubmitting] = useState(false);
  const [artist, setArtist] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [invitationCode, setInvitationCode] = useState('');
  const [timezone, setTimezone] = useState(null);
  const [referralMode, setReferralMode] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const redirectRoute = '/account-setup';
  const dispatch = useDispatch();
  const onboardingData = useSelector(selectOnboardingData, shallowEqual);

  const { isLoading, referral, referralError, referralFirstName } = useCheckCodeHook({ referralCode, setValue });

  useEffect((): void => {
    if (!referral) {
      return;
    }
    setReferralMode(true);
  }, [referral]);

  useEffect((): void => {
    const loadOnboardingData = async (): Promise<void> => {
      await dispatch(getOnboardingData());
    };

    const init = async (): Promise<void> => {
      await loadOnboardingData();
    };

    init().finally();
  }, [dispatch]);

  watch(['email', 'name']);

  useEffect((): void => {
    const params = queryString.parse(location.search) as {
      email?: string;
      artist?: string;
      code?: string;
      invitation_code?: string;
    };
    setArtist(params?.artist ?? '');
    setReferralCode(params?.code);
    setInvitationCode(params?.invitation_code);
    setValue('email', params?.email ?? '');
    if (!params?.invitation_code) {
      window.location.href = 'https://www.musiversal.com/unlimited';
    } else {
      history.push(Routes.SIGNUP);
    }
  }, []);

  useIsPartnerGuard();

  const redirectToPrivateArea = () => {
    setSubmitting(false);
    history.push(redirectRoute);
  };

  const redirectToCodeInvalidOrExpired = () => {
    setSubmitting(false);
    window.location.href = 'https://www.musiversal.com/invitation-expired';
  };

  const onSubmit = async data => {
    setSubmitting(true);

    Mixpanel.track(MixPanelSignUpEvents.CHOSE_SIGN_UP_METHOD, {
      [MixPanelChoseSignUpMethodProperties.METHOD]: SignupMethods.EMAIL
    });

    const signUpData = {
      user: {
        name: data.name,
        email: data.email?.toLowerCase(),
        timezone,
        password: data.password
      },
      method: 'official',
      invitation_code: invitationCode,
      referral_code: referralCode
    } as SignupDataInterface;

    try {
      await AuthService.logout();
      const res = await AuthService.signup(signUpData);
      if (res.status === 200) {
        await dispatch(getMe());
        localStorage.setItem('timezone', timezone);
        Mixpanel.track(MixPanelSignUpEvents.COMPLETED_SIGN_UP);
        cookieCutter.set('showOnboarding', true, { httpOnly: true });

        TagManager.dataLayer({
          dataLayer: {
            event: 'sign_up',
            method: 'email',
            user_id: res?.data?.user?.id
          }
        });

        await redirectToPrivateArea();
      }
    } catch (err) {
      if (err.response?.status === 401) {
        setSubmitting(false);
        if (Object.keys(err.response?.data).includes('invitation_code')) {
          setError('server', {
            type: 'manual',
            message: 'The invitation code is expired or invalid'
          });
          redirectToCodeInvalidOrExpired();
        } else {
          setError('email', {
            type: 'manual',
            message: 'That email already exists in Musiversal'
          });
        }
      } else {
        setError('server', {
          type: 'manual',
          message: 'We have some issues connecting to our servers. Please try again later.'
        });
      }
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const tz = moment.tz.guess();
    setTimezone(tz);
  }, []);

  return (
    <SplitContainer
      leftColumn={
        <div className={styles.container}>
          {!isLoading && (
            <>
              <div className={styles.logo}>
                <Icon name={'musiversal-logo'} />
              </div>
              {referralMode ? (
                <ReferralWelcome firstName={referralFirstName} setIsReferralMode={setReferralMode} />
              ) : (
                <>
                  <div className={styles.signupHeader}>
                    {/* <h1 className={styles.title}>{referral || referralError ? referralHeader : signUpHeader(artist, studioTrialsOnSignup)}</h1> */}
                    <h1 className={styles.title}>{signUpHeader(artist, studioTrialsOnSignup)}</h1>
                    <div className={styles.loginParagraph}>
                      <div className={styles.loginMessage}>Already have an account?</div>
                      <div className={styles.loginLink}>
                        <Link to={'/login'}>
                          <span className={styles.login}>{loginText}</span>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className={styles.form}>
                    <div className={styles.socialLogins}>
                      {loginWithFacebook && (
                        <FacebookLogin
                          invitationCode={invitationCode}
                          timezone={timezone}
                          callback={redirectToPrivateArea}
                          setError={setError}
                          isSignUp={true}
                        />
                      )}
                      {loginWithGoogle && (
                        <GoogleLoginButton
                          timezone={timezone}
                          invitationCode={invitationCode}
                          callback={redirectToPrivateArea}
                          setError={setError}
                          isSignUp
                        />
                      )}
                    </div>
                    {(loginWithFacebook || loginWithGoogle) && (
                      <div className={styles.divider}>
                        <div className={styles.divLine} />
                        <div className={styles.orText}>{orText}</div>
                        <div className={styles.divLine} />
                      </div>
                    )}

                    <form onSubmit={handleSubmit(onSubmit)} className={styles.formInner}>
                      <Input
                        placeholder={'Email Address'}
                        type={InputTypes.EMAIL}
                        name={'email'}
                        id={'email'}
                        registerRef={register('email', {
                          required: 'Required',
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: 'Invalid email address'
                          }
                        })}
                        errors={[errors.email?.message]}
                        clearErrors={() => clearErrors()}
                      />
                      <Input
                        placeholder={'Full Name'}
                        type={InputTypes.TEXT}
                        name={'name'}
                        id={'name'}
                        registerRef={register('name', {
                          required: 'Required',
                          pattern: {
                            // eslint-disable-next-line new-cap
                            value: XRegExp("^(?!\\s)([\\pL'-]+\\s)+([\\pL'-])+$"),
                            message: 'Your first name and last name are required'
                          }
                        })}
                        errors={[errors.name?.message]}
                        clearErrors={() => clearErrors()}
                      />
                      <Input
                        placeholder={'Password'}
                        type={InputTypes.PASSWORD}
                        name={'password'}
                        id={'password'}
                        registerRef={register('password', {
                          required: 'Required',
                          minLength: {
                            value: 8,
                            message: 'Password must have at least 8 characters'
                          }
                        })}
                        errors={[errors.password?.message, errors.provider?.message, errors.server?.message]}
                        clearErrors={() => clearErrors()}
                      />
                      <div className={styles.button}>
                        <Button
                          isSubmit
                          disabled={submitting}
                          width={'100%'}
                          buttonSize={ButtonSizes.LARGE}
                          buttonText={submitting ? buttonSubmittingText : buttonText}
                        />
                      </div>
                    </form>

                    <p className={styles.disclaimer}>
                      Signing up for a Musiversal account means you agree to the{' '}
                      <a href='https://www.musiversal.com/privacy-policy' target='_blank' rel='noreferrer'>
                        Privacy Policy
                      </a>{' '}
                      and{' '}
                      <a href='https://www.musiversal.com/terms-of-use' target='_blank' rel='noreferrer'>
                        Terms of Use
                      </a>
                      .
                    </p>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      }
      rightColumn={
        <div className={styles.rightColumnContainer}>
          <ListItems
            title={rightColumnTitle}
            bullets={onboardingData?.signup?.bullets ?? []}
          />
          {/* <div className={styles.trustedBy}>
          <TrustedBy message={'Trusted by professionals working for the world&apos;s biggest media platforms:'}/>
        </div> */}
        </div>
      }
    />
  );
};

export default Signup;
