import moment from 'moment-timezone';
import React, { ReactElement, useEffect, useState } from 'react';
import { Control, useForm, UseFormRegister, UseFormWatch } from 'react-hook-form';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { ModalInterface } from '../../../../interfaces/modal.interface';
import { getOnboardingModalData } from '../../../../store/actions/modals.actions';
import { selectOnboardingData } from '../../../../store/slices/global.slice';
import { setIsVideoModal } from '../../../../store/slices/modals.slice';
import { selectUser } from '../../../../store/slices/user.slice';
import ModalWrapper from '../../ModalWrapper/ModalWrapper';

import Button from '../../../../components/Atoms/v3/Button/Button';
import { ButtonSizes } from '../../../../enums/button-sizes.enum';

import styles from './VideoModal.module.scss';

import {
  headerTitle,
  headerSubtitle,
  buttonText
} from './VideoModal.const';


const VideoModal = ({ isOpen }: ModalInterface): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(selectUser, shallowEqual);
  const onboardingData = useSelector(selectOnboardingData, shallowEqual);

  const onRequestClose = (): void => {
    // Mark account with onboarding as true so this message won't show automatically every time
    history.push('/explore');
    dispatch(setIsVideoModal(false));
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      isPreventClosing={false}
      hideCloseIcon={false}
      className={styles.modal}
      onRequestClose={onRequestClose}
    >
      <div className={styles.container}>
        <div className={styles.videoArea}>
        <iframe
          src={onboardingData?.starting?.video_url ?? 'https://player.vimeo.com/video/667840546?h=e00d1a9cb9&title=0&byline=0&portrait=0'}
          width={'640'}
          height={'360'}
          frameBorder={'0'}
          allow={'autoplay; fullscreen; picture-in-picture'}
          allowFullScreen
        />
          {/* Youtube Config */}
          {/* <iframe
            width={'640'}
            height={'360'}
            src={`https://www.youtube.com/embed/${onboardingData?.starting?.video_url?.split('https://www.youtube.com/watch?v=')[1]}`}
            frameBorder={'0'}
            allow={'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'}
            allowFullScreen
            title={'What is Musiversal?'}
          /> */}
        </div>
        <div className={styles.header}>
          <div className={styles.title}>
            {headerTitle}
          </div>
          <div className={styles.subtitle}>
            {headerSubtitle}
          </div>
          <div className={styles.button}>
           <Button
              onClick={onRequestClose}
              disabled={false}
              width={'100%'}
              buttonSize={ButtonSizes.LARGE}
              buttonText={buttonText}
            />
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default VideoModal;
