import React, { ReactElement, useMemo, useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import countryList from 'country-list';

import { localStorageKeys } from '../../../constants/localStorageKeys.const';
import { EuropeanTaxableCountriesEnum } from '../../../constants/european-taxable-countries.const';
import { PORTUGUESE_TAX } from '../../../constants/european-tax.const';
import { editedCountryNames } from '../../../constants/country-list-rename.const';
import { PaymentProviders } from '../../../enums/payment-providers.enum';
import { ModalTypes } from '../../../enums/modal-types.enum';
import { getPlanPriceIdByEnvHelper } from '../../../helpers/get-plan-price-id-by-env.helper';
import { getUserCreditsLeftHelper } from '../../../helpers/get-user-credits-left.helper';
import { ModalInterface } from '../../../interfaces/modal.interface';
import PaypalService from '../../../services/subscriptions/paypal.service';
import StripeService from '../../../services/subscriptions/stripe.service';
import { selectCurrentPlan, setIsShowGlobalLoader } from '../../../store/slices/global.slice';
import { setIsShowTopUpModal, setIsShowBillingAddressModal, selectTopupsModal } from '../../../store/slices/modals.slice';
import { selectUser } from '../../../store/slices/user.slice';
import StyledSelectV2 from '../../Atoms/StyledSelectV2/StyledSelectV2';
import Button from '../../Atoms/Button/Button';
import VATBreakdown from '../../Atoms/VATBreakdown/VATBreakdown';
import Icon from '../../Atoms/Icon/Icon';
import WarningV2 from '../../Atoms/WarningV2/WarningV2';
import RadioWithIcon from '../../Atoms/RadioWithIcon/RadioWithIcon';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import { showPaymentMethodModalStatuses } from '../PlansModal/PlansModal';
import styles from './TopUpModal.module.scss';

countryList.overwrite(editedCountryNames); // needed to refactor some of country names, that were too long or too formal
const countries = countryList.getData();

countries.sort((a, b) => {
  if (a.name === 'United States' ||
    a.name === 'Canada' ||
    a.name === 'United Kingdom') {
    return -1;
  } else if (
    b.name === 'United States' ||
    b.name === 'Canada' ||
    b.name === 'United Kingdom') {
    return 1;
  } else if (a.name < b.name) {
    return -1;
  }
  return 0;
});

const TopUpModal = ({ isOpen }: ModalInterface): ReactElement => {
  const user = useSelector(selectUser, shallowEqual);
  const { warningType, message, artistSlug, initialAmount = 1 } = useSelector(selectTopupsModal, shallowEqual) || {};

  const {
    topup_price: topupPrice,
    country: userCountry
  } = user;

  const { topup_price: currentPlanTopupPrice } = useSelector(selectCurrentPlan, shallowEqual) || {};
  const dispatch = useDispatch();
  const { handleSubmit, control, getValues, watch, formState: { errors }, clearErrors, register, reset } = useForm({ paymentsProvider: PaymentProviders.STRIPE });

  const ppWatch = watch('paymentsProvider');

  const [counter, setCounter] = useState(initialAmount);

  const onRequestClose = (): void => {
    reset();
    dispatch(setIsShowTopUpModal({ isShown: false }));
  };

  const openEditAddress = (): void => {
    dispatch(setIsShowTopUpModal({ isShown: false }));
    dispatch(setIsShowBillingAddressModal(true));
  };

  const modifyCounter = (amount: -1 | 1): (() => void) => (): void => {
    if (amount === -1 && counter === 1) {
      return;
    }
    setCounter(counter + amount);
  };

  const showVAT = EuropeanTaxableCountriesEnum.includes(userCountry) ?? false;

  const applyTax = (): void => {
    if (EuropeanTaxableCountriesEnum.includes(userCountry)) {
      return 1 + (PORTUGUESE_TAX / 100);
    }
    return 1;
  };

  useEffect(() => {
    setCounter(initialAmount);
  },[initialAmount]);

  const total = (): ReactElement => {
    const crossedOut =
      +currentPlanTopupPrice > topupPrice ? (
        <span className={styles.crossedOut}>${(counter * currentPlanTopupPrice * applyTax()).toFixed(2)}</span>
      ) : (
        ''
      );
    return (
      <span className={styles.totalPrice}>
        Pay {crossedOut} ${(counter * topupPrice * applyTax()).toFixed(2)}
      </span>
    );
  };

  const totalAmount = counter * topupPrice;

  const onTopUp = async (): Promise<void> => {
    dispatch(setIsShowGlobalLoader(true));
    // Process request
    const data = getValues();
    const { paymentsProvider } = getValues();
    localStorage.setItem(localStorageKeys.SESSIONS_LEFT, getUserCreditsLeftHelper(user).toString());
    try {
      const res =
        paymentsProvider === PaymentProviders.PAYPAL
          ? (await PaypalService.createTopUp(counter)).data
          : (await StripeService.createTopUp(counter)).data;
      window.location.href = res.url;
      dispatch(setIsShowGlobalLoader(false));
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setIsShowGlobalLoader(false));
    }
  };

  const paymentOptions = [
    {
      id: PaymentProviders.STRIPE,
      value: PaymentProviders.STRIPE,
      label: 'Credit Card',
      icon: 'credit-card'
    },
    {
      id: PaymentProviders.PAYPAL,
      value: PaymentProviders.PAYPAL,
      label: 'Paypal',
      icon: PaymentProviders.PAYPAL
    }
  ];

  return (
    <ModalWrapper isOpen={isOpen} onRequestClose={onRequestClose} modalType={ModalTypes.DIALOG} className={styles.modal}>
      {warningType && (
        <div className={styles.warningContainer}>
          <WarningV2
            icon={'alert-triangle-dark'}
            content={message}
          />
        </div>
      )}
      <div className={styles.counterContainer}>
        <p className={styles.label}>Buy credits</p>
        <div className={styles.counter}>
          <button className={styles.counterButton} disabled={counter === 1} onClick={modifyCounter(-1)}>
            <Icon name={'minusIcon'} />
          </button>
          <span className={styles.amount}>{counter}</span>
          <button className={styles.counterButton} onClick={modifyCounter(1)}>
            <Icon name={'plusIcon'} />
          </button>
        </div>
      </div>
      <div className={styles.paymentOptions}>
        {paymentOptions.map(({ id, label, value, icon }) => (
          <div key={id} className={styles.radioOption}>
            <RadioWithIcon
              required
              name='paymentsProvider'
              control={control}
              id={id}
              value={value}
              label={label}
              icon={icon}
              defaultChecked={value === PaymentProviders.STRIPE}
              labelClassName={styles.reason}
            />
          </div>
        ))}
      </div>
      { showVAT && (
        <div className={styles.VATBreakdown}>
          <VATBreakdown amount={totalAmount} template={'light'} />
        </div>
      )}
      <div className={styles.modalButtons}>
        <Button className={styles.payButton} onClick={onTopUp}>
          {total()}
        </Button>
        <a className={styles.cancelButton} onClick={onRequestClose}>
          Cancel
        </a>
      </div>
      <div className={styles.footer}>
        <div className={styles.secureCheckout}>
          Billing Country: <a onClick={openEditAddress}>{countries.find(({ code }) => code === userCountry)?.name}</a>
        </div>
        <div className={styles.secureCheckout}>
          <Icon name='lock' className={styles.lockIcon} /> Secure checkout
        </div>
      </div>
    </ModalWrapper>
  );
};

export default TopUpModal;
