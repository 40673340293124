import { UserCohorts } from '../enums/cohorts.enum';
import { UserStatuses } from '../enums/user-statuses.enum';

export const getUserCohort = (user: any): string => {
  const { status: userStatus, allow_subscribe: allowSubscribe } = user;
  switch (userStatus) {
    case UserStatuses.SIGNUP:
      return UserCohorts.PUBLIC;
    case UserStatuses.WAITLISTER:
      return UserCohorts.PUBLIC;
    case UserStatuses.INVITED:
      return UserCohorts.MEMBER;
    case UserStatuses.UNSUBSCRIBED:
      // if (allowSubscribe) {
        return UserCohorts.MEMBER;
      // }
      // return UserCohorts.PUBLIC;
    case UserStatuses.CANCELLED:
      return UserCohorts.CANCELLED;
    case UserStatuses.UNLIMITED:
      return UserCohorts.MEMBER;
    case UserStatuses.PENDING_CANCELLATION:
      return UserCohorts.MEMBER;
    case UserStatuses.STAFF:
      return UserCohorts.PUBLIC;
    default:
      return UserCohorts.PUBLIC;
  }
};

export const getUserCohortBillboard = (user: any): string => {
  const { status: userStatus, allow_subscribe: allowSubscribe } = user;
  switch (userStatus) {
    case UserStatuses.SIGNUP:
      return UserCohorts.PUBLIC;
    case UserStatuses.WAITLISTER:
      return UserCohorts.PUBLIC;
    case UserStatuses.INVITED:
      if (allowSubscribe) {
        return UserCohorts.ONDEMAND;
      }
      return UserCohorts.LEGACY;
    case UserStatuses.UNSUBSCRIBED:
      if (allowSubscribe) {
        return UserCohorts.ONDEMAND;
      }
      return UserCohorts.LEGACY;
    case UserStatuses.CANCELLED:
      if (allowSubscribe) {
        return UserCohorts.CANCELLED;
      }
      return UserCohorts.LEGACY;
    case UserStatuses.UNLIMITED:
      return UserCohorts.MEMBER;
      break;
    case UserStatuses.PENDING_CANCELLATION:
      return UserCohorts.PUBLIC;
      break;
    default:
      return UserCohorts.PUBLIC;
      break;
  }
};
