import { CustomPhoneInput, Button, ButtonTypes } from '@musiversal/musiversal-components';
import moment from 'moment-timezone';
import React, { ReactElement, useState, useEffect } from 'react';
// Monkey patch to get the UI working
import 'react-phone-number-input/style.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import axios from 'axios';

import imageUrl from '../../../../../assets/images/waitlist-modal/waitlist-modal.png';
import { ModalTypes } from '../../../../enums/modal-types.enum';
import { ModalInterface } from '../../../../interfaces/modal.interface';
import UserService from '../../../../services/user/user.service';
import { setIsShowWaitlistModal } from '../../../../store/slices/modals.slice';
import { selectUser } from '../../../../store/slices/user.slice';
import ModalWrapper from '../../ModalWrapper/ModalWrapper';
import { title, getBody, bodyPhone, ctaPhone, ctaLink, circleURL, bodyCall1, bodyCall2, inviteDate } from './WaitlistModal.const';
import styles from './WaitlistModal.module.scss';

const WaitlistModal = ({ isOpen }: ModalInterface): ReactElement => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser, shallowEqual);

  const [defaultCountry, setDefaultCountry] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [hasPhone, setHasPhone] = useState(false);
  const [joinedWaitlistDate, setJoinedWaitlistDate] = useState(null);
  const [hasStartedTyping, setHasStartedTyping] = useState(false);

  useEffect(() => {
    const getGeoInfo = async () => {
      try {
        const response = await axios.get('https://ipapi.co/json/');
        const data = response.data;
        setDefaultCountry(data.country_code);
      } catch (error) {
        console.log(error);
        setDefaultCountry('US');
      }
    };
    const fetchWaitlistStatus = async () => {
      try {
        await getGeoInfo();
        const res = await UserService.getWaitlistStatus();
        if (res?.status === 200) {
          const { data } = res;
          setHasPhone(data?.has_phone);
          setJoinedWaitlistDate(data?.join_date);
          if (!data?.has_phone) {
            getGeoInfo();
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchWaitlistStatus();
  }, []);

  const handleJoinCommunity = () => {
    window.open(circleURL, '_blank');
  };

  const handleAddNumber = async () => {
    try {
      const phoneInfo = {
        phone: phoneNumber
      };
      const res = await UserService.updatePhoneNumber(phoneInfo);
      if (res?.status === 200) {
        setHasPhone(true);
      } else {
        setHasPhone(false);
      }
    } catch (err) {
      setHasPhone(false);
    }
  };

  const getInviteDurationText = (): string => {
    // get weeks from inviteDate to now
    const invitationExpectedDate = moment(joinedWaitlistDate).add(3, 'weeks').add(1, 'day');
    const weeks = invitationExpectedDate.diff(moment(), 'weeks');
    if (weeks > 1) {
      return getBody(`${weeks} week${weeks > 1 ? 's' : ''}`);
    }
    return 'Your journey begins soon.';
  };

  const closeWaitlistModal = () => {
    dispatch(setIsShowWaitlistModal(false));
  };

  return (
    <>
      <ModalWrapper
        isPreventClosing={true}
        isOpen={isOpen}
        modalType={ModalTypes.DIALOG}
        onRequestClose={closeWaitlistModal}
      >
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.image}>
              <img src={imageUrl} alt='Waitlist image' style={{ maxWidth: '100%', maxHeight: '100%' }} />
            </div>
          </div>
          <div className={styles.titleTime}>
            <div className={styles.title}>{title}</div>
            {/* <div>{getInviteDurationText()}</div> */}
            <div className={styles.time}>{inviteDate}</div>
            <div className={styles.bodyCall}>
              {hasPhone && <div>{bodyCall1}</div>}
              {hasPhone && <div>{bodyCall2}</div>}
            </div>
          </div>
          <div className={styles.phone}>
            {hasPhone ? (
              <Button
                buttonType={ButtonTypes.PRIMARY_OUTLINE}
                hasIcon={true}
                iconName='external_link'
                buttonText={ctaLink}
                onClick={handleJoinCommunity}
              />
            ) : (
              <>
                <div className={styles.phoneCopy}>
                  {bodyPhone}
                </div>
                <div className={styles.phoneButton}>
                  <CustomPhoneInput
                    phoneNumber={phoneNumber}
                    defaultCountry={defaultCountry}
                    setPhoneNumber={setPhoneNumber}
                    setHasStartedTyping={setHasStartedTyping}
                    onCountryChange={() => console.log('country changed')}
                    hasStartedTyping={hasStartedTyping}
                  />
                  <Button buttonText={ctaPhone} onClick={handleAddNumber} />
                </div>
              </>
            )}
          </div>
        </div>
      </ModalWrapper>
    </>
  );
};

export default WaitlistModal;
