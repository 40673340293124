import React, { useEffect, useRef, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import TagManager from 'react-gtm-module';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { NavTabs } from '@musiversal/musiversal-components';
import BasicInfoForm from '../../../../components/Molecules/Profile/BasicInfoForm/BasicInfoForm.js';
import Billing from '../../../../components/Molecules/Profile/Billing/Billing.js';
import NotificationsForm from '../../../../components/Molecules/Profile/NotificationsForm/NotificationsForm.js';
import SecurityForm from '../../../../components/Molecules/Profile/SecurityForm/SecurityForm.js';
import { localStorageKeys } from '../../../../constants/localStorageKeys.const';

import { selectUser } from '../../../../store/slices/user.slice';
import { getMe } from '../../../../store/actions/user.actions';
import { useHistory, useLocation } from 'react-router-dom';
import { selectIsPartnersDashboard, setIsShowGlobalLoader } from '../../../../store/slices/global.slice';
import { PaymentProviders } from '../../../../enums/payment-providers.enum';
import { ToastTypes } from '../../../../enums/toast-types.enum';
import { getCurrentPlan } from '../../../../store/actions/global.actions';
import { useToast } from '../../../../hooks/useToast';
import { parseQuery } from '../../../../helpers/url.helper';
import { ProfileTabs } from '../../../../enums/profile-tabs.enum';
import { UserStatuses } from '../../../../enums/user-statuses.enum';
import { setIsShowHowItWorksModal } from '../../../../store/slices/modals.slice';
import { isAllowedReferralHelper } from '../../../../helpers/is-allowed-referral.helper';
import { Routes } from '../../../../enums/routes.enum';
import Referral from '../../../../components/Molecules/Profile/Referral/Referral';
import { getReferrals } from '../../../../store/actions/referral.actions';
import { getUserCreditsLeftHelper } from '../../../../helpers/get-user-credits-left.helper';

import styles from './Profile.module.scss';

const INTERVAL_PERIOD_IN_MS = 1000;
const GET_ME_LIMIT = 20;

const Profile = () => {
  const user = useSelector(selectUser, shallowEqual);
  const isPartnersDashboard = useSelector(selectIsPartnersDashboard, shallowEqual);
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(ProfileTabs.PROFILE);
  const { profileInfoButton } = useFlags();
  const location = useLocation();
  const history = useHistory();
  const { sendToast } = useToast();

  const getMeInterval = useRef();
  const getMeRequestCounter = useRef(0);

  useEffect(() => {
    const getPlan = async () => {
      await dispatch(getCurrentPlan(user));
    };
    getPlan().finally();
  }, [user, dispatch]);

  useEffect(() => {
    if (!isAllowedReferralHelper(user.status, isPartnersDashboard)) {
      return;
    }
    const getReferralsWrapper = async () => {
      await dispatch(getReferrals());
    };
    getReferralsWrapper().finally();
  }, [dispatch, user.status, isPartnersDashboard]);

  const selectTab = tab => () => {
    history.push(tab === ProfileTabs.REFERRAL ? Routes.REFERRAL : Routes.PROFILE);
    setSelectedTab(tab);
  };

  useEffect(() => {
    if (location.pathname !== Routes.REFERRAL) {
      return;
    }
    if (!isAllowedReferralHelper(user.status, isPartnersDashboard)) {
      selectTab(ProfileTabs.PROFILE)();
      return;
    }
    setSelectedTab(ProfileTabs.REFERRAL);
  }, [location.pathname, selectTab, user.status]);

  useEffect(() => {
    if (!location.hash) {
      return;
    }
    const hashWithoutParams = location.hash.split('?')[0];
    setSelectedTab(hashWithoutParams.split('#')[1]);
    history.push(location.pathname);
  }, [location, history]);

  useEffect(() => {
    if (!location.hash) {
      return;
    }
    const params = ['billing', 'session_id'];
    const isTopUpParam = 'purchase=topup';
    const isTopUp = location.hash?.includes(isTopUpParam) && !location.hash?.includes('product_type=Pack');
    const paramsObj = parseQuery(location?.hash?.split('#billing')[1]);
    if (isTopUp) {
      TagManager?.dataLayer({
        dataLayer: {
          event: 'purchase',
          ecommerce: {
            transaction_id: paramsObj?.session_id,
            value: paramsObj?.product_price / 100,
            currency: 'USD',
            payment_type: paramsObj?.payment_provider,
            items: [
              {
                item_name: paramsObj?.product_name,
                item_category: paramsObj?.product_type,
                price: (paramsObj?.product_price / 100) / (location.hash?.includes('product_type=Pack') ? 1 : (paramsObj?.credits / 1)),
                quantity: location.hash?.includes('product_type=Pack') ? 1 : paramsObj?.credits / 1
              }
            ]
          },
          credits: paramsObj?.credits / 1
        }
      });
      sendToast({ message: 'Credits added', toastType: ToastTypes.SUCCESS });
    }
    // if (location.hash?.includes('product_type=Pack')) {
    //     // if (performance?.navigation.type === 0) {
    //       TagManager?.dataLayer({
    //         dataLayer: {
    //           event: 'purchase',
    //           ecommerce: {
    //             transaction_id: paramsObj?.session_id,
    //             value: paramsObj?.product_price,
    //             currency: 'USD',
    //             payment_type: paramsObj?.payment_provider,
    //             items: [
    //               {
    //                 item_name: paramsObj?.product_name,
    //                 item_category: paramsObj?.product_type,
    //                 price: paramsObj?.product_price,
    //                 quantity: location.hash?.includes('product_type=Pack') ? 1 : paramsObj?.credits / 1
    //               }
    //             ]
    //           },
    //           credits: paramsObj?.credits / 1
    //         }
    //       });
    //     // }
    // }
    const areParamsIncluded = params.every(param => location.hash?.includes(param));
    if (!areParamsIncluded) {
      return;
    }

    dispatch(setIsShowGlobalLoader(true));

    getMeInterval.current = setInterval(() => {
      getMeRequestCounter.current += 1;

      if (getMeRequestCounter.current > GET_ME_LIMIT && user.subscription.paymentsProvider !== PaymentProviders.PAYPAL) {
        clearInterval(getMeInterval.current);
        dispatch(setIsShowGlobalLoader(false));
        return;
      }

      dispatch(getMe());
    }, INTERVAL_PERIOD_IN_MS);
  }, [location.hash, dispatch, user.subscription]);

  useEffect(() => {
    const currentPlanId = localStorage.getItem(localStorageKeys.CURRENT_PLAN_ID);
    const currentUserStatus = localStorage.getItem(localStorageKeys.USER_STATUS);
    const currentSessionsLeft = parseInt(localStorage.getItem(localStorageKeys.SESSIONS_LEFT));

    const stopInterval = () => {
      clearInterval(getMeInterval.current);
      history.push(location.pathname);
      dispatch(setIsShowGlobalLoader(false));
    };

    if (currentUserStatus && user.status !== currentUserStatus) {
      if (user.status === UserStatuses.UNLIMITED) {
        try {
          TagManager.dataLayer({
            dataLayer: {
              event: 'subscriber_acquired',
              user_id: user?.id
            }
          });
        } catch (err) {
          console.error(err);
        }
        sendToast({ message: 'Subscription enabled', toastType: ToastTypes.SUCCESS });
      }
      clearInterval(getMeInterval.current);
      localStorage.setItem(localStorageKeys.USER_STATUS, user.status);
      stopInterval();
    }
    if (user.subscription?.currentPrice !== currentPlanId) {
      localStorage.setItem(localStorageKeys.CURRENT_PLAN_ID, user.subscription?.currentPrice);
      stopInterval();
    }
    const userCredits = getUserCreditsLeftHelper(user);
    if (userCredits !== currentSessionsLeft) {
      localStorage.setItem(localStorageKeys.SESSIONS_LEFT, userCredits.toString());
      stopInterval();
    }
    //eslint-disable-next-line
  }, [user, location.pathname]);

  const billingNotAllowedStatuses = [UserStatuses.UNSUBSCRIBED, UserStatuses.INVITED];

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.header}>
            <h1 className={styles.headerTitle}>My Profile</h1>
          </div>

          <NavTabs
            tabs={[
              {
                label: 'Profile',
                selected: selectedTab === ProfileTabs.PROFILE,
                onClick: selectTab(ProfileTabs.PROFILE)
              },
              {
                label: 'Notifications',
                selected: selectedTab === ProfileTabs.NOTIFICATIONS,
                onClick: selectTab(ProfileTabs.NOTIFICATIONS)
              },
              {
                label: 'Security',
                selected: selectedTab === ProfileTabs.SECURITY,
                onClick: selectTab(ProfileTabs.SECURITY)
              },
              {
                label: 'Billing',
                selected: selectedTab === ProfileTabs.BILLING,
                onClick: selectTab(ProfileTabs.BILLING),
                isHidden: billingNotAllowedStatuses.includes(user?.status)
              },
              {
                label: 'Referral',
                selected: selectedTab === ProfileTabs.REFERRAL,
                onClick: selectTab(ProfileTabs.REFERRAL),
                isHidden: true
              }
            ]}
          />

          <div className={styles.tabsContent}>
            {selectedTab === ProfileTabs.PROFILE && (
              <div className={styles.formContentSmall}>
                <BasicInfoForm user={user} />
              </div>
            )}
            {selectedTab === ProfileTabs.NOTIFICATIONS && (
              <div className={styles.formContentSmall}>
                <NotificationsForm />
              </div>
            )}
            {selectedTab === ProfileTabs.SECURITY && (
              <div className={styles.formContentSmall}>
                <SecurityForm user={user} />
              </div>
            )}
            {selectedTab === ProfileTabs.BILLING && (
              <div className={styles.formContentLarge}>
                <Billing />
              </div>
            )}
            {selectedTab === ProfileTabs.REFERRAL && (
              <div className={styles.formContentLarge}>
                <Referral />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
