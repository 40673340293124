import moment from 'moment-timezone';
import React, { useMemo, ReactElement, useEffect, useState } from 'react';
import { Control, useForm, UseFormRegister, UseFormWatch } from 'react-hook-form';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { ModalInterface } from '../../../../interfaces/modal.interface';
import { getOnboardingModalData } from '../../../../store/actions/modals.actions';
import {
  selectIsPartnersDashboard,
  setIsShowGlobalLoader,
  selectOnboardingData
} from '../../../../store/slices/global.slice';
import {
  setIsHelpModal,
  setIsVideoModal,
  setIsHireProducerModal
} from '../../../../store/slices/modals.slice';

import { selectUser } from '../../../../store/slices/user.slice';
import ModalWrapper from '../../ModalWrapper/ModalWrapper';
import IlustrationButton from '../../../../components/Atoms/v3/IlustrationButton/IlustrationButton';

import styles from './HelpModal.module.scss';

import {
  headerTitle,
  headerSubtitle,
  linkText,
  message,
  options
} from './HelpModal.const';

export enum OptionActions {
  OPEN_HELP_CENTER = 'openHelpCenter',
  OPEN_CALENDLY = 'openCalendly',
  OPEN_SUPPORT_FORM = 'openSupportForm'
}

const HelpModal = ({ isOpen }: ModalInterface): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(selectUser, shallowEqual);
  const onboardingData = useSelector(selectOnboardingData, shallowEqual);
  const isPartnersDashboard = useSelector(selectIsPartnersDashboard, shallowEqual);

  const goToCalendly = () => {
    window.open(onboardingData.starting.calendly_link);
  };

  const contactSupportLink = useMemo(() =>
  `https://share.hsforms.com/18FoFHA41TZ-c-Dku-Wpcpg4zn1a?__hstc=22948580.d84bcc8487e7c4952ad4187adc305fc0.1637051653108.1641209517908.1641290813033.70&__hssc=22948580.1.1641290813033&__hsfp=7147803&your_name=${user.name}&email=${user.email}`, [user.name, user.email]);

  const goToSupportForm = () => {
    window.open(contactSupportLink);
  };

  const goToHelpCenter = () => {
    window.open('https://help.musiversal.com');
  };

  const selectOnClickFunction = (option): any => {
    if (option.action === OptionActions.OPEN_CALENDLY) {
      return goToCalendly;
    }
    if (option.action === OptionActions.OPEN_HELP_CENTER) {
      return goToHelpCenter;
    }
    return goToSupportForm;
  };

  const onRequestClose = (): void => {
    // Mark account with onboarding as true so this message won't show automatically every time
    dispatch(setIsHelpModal(false));
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      isPreventClosing={false}
      className={styles.modal}
      onRequestClose={onRequestClose}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>
            {headerTitle}
          </div>
          <div className={styles.subtitle}>
            {headerSubtitle}
          </div>
        </div>
        <div className={styles.optionsList}>
          { options.map((op, idx) =>
            <div key={idx} className={styles.option}>
              <IlustrationButton
                key={idx}
                title={op.title}
                description={op.description}
                ilustrationIcon={op.ilustrationIcon}
                onClick={selectOnClickFunction(op)}
              />
            </div>
          )}
        </div>
        {/* <div className={styles.continue}>
          <div className={styles.link} onClick={onRequestClose}>
            {linkText}
          </div>
          <div className={styles.message}>
            {message}
          </div>
        </div> */}
      </div>
    </ModalWrapper>
  );
};

export default HelpModal;
