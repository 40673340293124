import classNames from 'classnames';
import cookieCutter from 'cookie-cutter';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';

import Avatar from '../Avatar/Avatar';
import Button from '../Button/Button';
import Icon from '../../Icon/Icon';
import CreditsDisplay from '../../CreditsDisplay/CreditsDisplay';
import ActiveSessionsCounter from '../ActiveSessionsCounter/ActiveSessionsCounter';
import {
  setIsHelpModal,
  setIsShowSubscriptionModal,
  setIsVideoModal,
  setIsShowRejoinWaitlistModal
} from '../../../../store/slices/modals.slice';
import UserService from '../../../../services/user/user.service';
import { hideToast, showToast } from '../../../../store/slices/toast.slice';
import { selectBannerHeight, setIsShowGlobalLoader, selectPlans } from '../../../../store/slices/global.slice';
import { useIsMobileResolution } from '../../../../hooks/useIsMobileResolution';
import { selectUser } from '../../../../store/slices/user.slice';
import { UserStatuses } from '../../../../enums/user-statuses.enum';
import { ButtonSizes } from '../../../../enums/button-sizes.enum';
import { isAllowedReferralHelper } from '../../../../helpers/is-allowed-referral.helper';
import { executeResumeSubscription } from '../../../../helpers/paymentActions.helper';

import styles from './Navbar.module.scss';

const billingAreaPath = '/profile#billing';

interface NavbarInterface {
  spaceBottom: boolean;
}

const Navbar = ({ spaceBottom }: NavbarInterface): ReactElement => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const bannerHeight = useSelector(selectBannerHeight);
  const plans = useSelector(selectPlans, shallowEqual);
  const isMobileResolution = useIsMobileResolution();
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [currentPath, setCurrentPath] = useState(null);
  const onClick = () => setIsActive(!isActive);
  const history = useHistory();
  const location = useLocation();

  // we get the currentPath from location
  useEffect(() => {
    setCurrentPath(location.pathname);
    console.log(location);
  }, [location]);

  useEffect(() => {
    setIsActive(false);
  }, [location]);

  const logout = async () => {
    // We delete all cookies
    cookieCutter.set('userToken', null, { expires: new Date(0) });
    localStorage.removeItem('avatar');
    history.push('/logout');
  };

  const openHowItWorksModal = async () => {
    dispatch(setIsVideoModal(true));
    setIsActive(false);
  };

  const openHelpModal = async () => {
    dispatch(setIsHelpModal(true));
    setIsActive(false);
  };

  const resumeSubscription = async (): Promise<void> => {
    await executeResumeSubscription(user, plans, dispatch, history);
  };

  const sendToast = (message, toastType, delay = 4000) => {
    const payload = {
      message,
      toastType
    };
    dispatch(showToast(payload));
    setTimeout(() => {
      dispatch(hideToast());
    }, delay);
  };

  const openRejoinWaitlistModal = async () => {
    if (user.status === UserStatuses.CANCELLED) {
      const response = await UserService.rejoinWaitlistNotification({});
      sendToast(response?.data?.msg, 'success');
    } else {
      dispatch(setIsShowRejoinWaitlistModal(true));
    }
  };

  const openSubscribeModal = () => async () => {
    // dispatch(setIsShowSubscriptionModal(true));
  };

  const showActionButton = useMemo(
    () => [UserStatuses.UNSUBSCRIBED, UserStatuses.PENDING_CANCELLATION, UserStatuses.CANCELLED].includes(user.status),
    [user.status]
  );

  const showSubscribeButton = useMemo(
    () => [UserStatuses.UNSUBSCRIBED, UserStatuses.CANCELLED, UserStatuses.INVITED].includes(user.status) && user.allow_subscribe,
    [user.status]
  );

  const showJoinWaitlistButton = useMemo(
    () => [UserStatuses.UNSUBSCRIBED, UserStatuses.CANCELLED].includes(user.status) && !user.allow_subscribe,
    [user.status]
  );

  const showResumeSubscriptionButton = useMemo(
    () => [UserStatuses.PENDING_CANCELLATION].includes(user.status),
    [user.status]
  );

  const showSpeedMeter = useMemo(
    () => [UserStatuses.PENDING_CANCELLATION, UserStatuses.UNLIMITED].includes(user.status),
    [user.status]
  );

  useEffect(() => {
    const pageClickEvent = e => {
      // If the active element exists and is clicked outside of
      if (dropdownRef.current !== null && !dropdownRef.current.contains(e.target)) {
        setIsActive(!isActive);
      }
    };

    if (isActive) {
      window.addEventListener('click', pageClickEvent);
    }

    return () => {
      window.removeEventListener('click', pageClickEvent);
    };
  }, [isActive]);


  const contactSupportLink = useMemo(() =>
    `https://share.hsforms.com/18FoFHA41TZ-c-Dku-Wpcpg4zn1a?__hstc=22948580.d84bcc8487e7c4952ad4187adc305fc0.1637051653108.1641209517908.1641290813033.70&__hssc=22948580.1.1641290813033&__hsfp=7147803&your_name=${user.name}&email=${user.email}`, [user.name, user.email]);

  return (
    <>
      <div
        className={classNames(
          styles.navbar,
          spaceBottom === false && styles.noMargin,
          showActionButton && styles.mobileMargin
        )}
        style={{ top: `${bannerHeight}px` }}
      >
        <Link to='/explore' className={styles.navbarLogo}>
          <Icon name={'navbarLogo'} />
        </Link>
        <nav className={styles.navbarNav}>
          <Link
            to='/sessions'
            className={styles.navbarNavLink + ' ' + (currentPath === '/sessions' ? styles.isActive : '')}
          >
            My Sessions
          </Link>
          <Link
            to='/explore'
            className={styles.navbarNavLink + ' ' + (currentPath === '/explore' ? styles.isActive : '')}
          >
            Explore
          </Link>
        </nav>
        <div className={styles.dropdown__container}>
          {!isMobileResolution && (
            <div className={styles.creditsDisplay}>
              {/* <CreditsDisplay
                useIsMobileResolution={useIsMobileResolution}
              /> */}
            </div>
          )}
          {showSubscribeButton && !isMobileResolution && (
            <Button
              onClick={() => dispatch(setIsShowSubscriptionModal(true))}
              className={styles.getFullAccessButton}
              width={'100%'}
              buttonSize={ButtonSizes.LARGE}
              buttonText={'Subscribe'}
            />
          )}
          {showJoinWaitlistButton && !isMobileResolution && (
            <Button
              onClick={openRejoinWaitlistModal}
              className={styles.getFullAccessButton}
              width={'100%'}
              buttonSize={ButtonSizes.LARGE}
              buttonText={'Join the waitlist'}
            />
          )}
          {showResumeSubscriptionButton && !isMobileResolution && (
            <Button
              onClick={() => resumeSubscription()}
              className={styles.getFullAccessButton}
              width={'100%'}
              buttonSize={ButtonSizes.LARGE}
              buttonText={'Resume subscription'}
            />
          )}
          <button onClick={onClick} className={styles.dropdown__trigger}>
            <Avatar
              key={user?.id}
              id={user?.id}
              name={user?.name}
              user={user}
              isUserAvatar={true}
              showSpeedMeter={showSpeedMeter}
            />
          </button>
          <nav ref={dropdownRef} className={styles.dropdown__menu + ' ' + (isActive ? styles.active : styles.inactive)}>
            <ul>
              {isMobileResolution && (showSubscribeButton || showJoinWaitlistButton || showResumeSubscriptionButton) && (
                <li className={styles.liWithButton}>
                  {showSubscribeButton && (
                    <Button
                      onClick={() => dispatch(setIsShowSubscriptionModal(true))}
                      className={styles.buttonInDropdownMenu}
                      width={'100%'}
                      buttonSize={ButtonSizes.LARGE}
                      buttonText={'Subscribe'}
                    />
                  )}
                  {showJoinWaitlistButton && (
                    <Button
                      onClick={openRejoinWaitlistModal}
                      className={styles.buttonInDropdownMenu}
                      width={'100%'}
                      buttonSize={ButtonSizes.LARGE}
                      buttonText={'Join the waitlist'}
                    />
                  )}
                  {showResumeSubscriptionButton && (
                    <Button
                      onClick={() => dispatch(setIsShowSubscriptionModal(true))}
                      className={styles.buttonInDropdownMenu}
                      width={'100%'}
                      buttonSize={ButtonSizes.LARGE}
                      buttonText={'Resume subscription'}
                    />
                  )}
                </li>
              )}
              {user?.speed_limit > 0 && user.status != UserStatuses.UNSUBSCRIBED && (
                <li>
                  <div className={styles.counterContainer}>
                    <ActiveSessionsCounter
                      sessionsUsed={user?.sessions_used}
                      sessionsTotal={user?.speed_limit}
                    />
                  </div>
                </li>
              )}
              <li>
                <Link to='/profile#profile'>
                  <span className={styles.dropdown__menuItemIcon}>
                    <Icon name={'profile'} />
                  </span>
                  Profile
                </Link>
              </li>
              <li>
                <a onClick={openHowItWorksModal}>
                  <span className={styles.dropdown__menuItemIcon}>
                    <Icon name={'video'} />
                  </span>
                  How it works
                </a>
              </li>
              {/* {isAllowedReferralHelper(user.status, isPartnersDashboard) && <li>
                <Link to='/referral'>
                  <span className={styles.dropdown__menuItemIcon}>
                    <Icon name={'referral'} />
                  </span>
                  Referral
                </Link>
              </li>} */}
              <li>
                <a onClick={openHelpModal}>
                  <span className={styles.dropdown__menuItemIcon}>
                    <Icon name={'helpCenter'} />
                  </span>
                  Help & Support
                </a>
              </li>
              {/* <li>
                <a href={contactSupportLink} target='_blank' rel='noreferrer'>
                  <span className={styles.dropdown__menuItemIcon}>
                    <Icon name={'contactSupport'} />
                  </span>
                  Contact Support
                </a>
              </li> */}
              <li>
                <a onClick={logout}>
                  <span className={styles.dropdown__menuItemIcon}>
                    <Icon name={'logout'} />
                  </span>
                  Logout
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Navbar;
