import classNames from 'classnames';
import React, { useMemo, useEffect, useState, ReactElement } from 'react';

import DefaultAvatar from '../../../Icons/Avatar.svg';
import Icon from '../../Icon/Icon';
import styles from './Avatar.module.scss';

interface AvatarInterface {
  id?: number;
  avatarUrl?: string;
  isUserAvatar?: boolean;
  name: string;
  user?: any;
  altUrl?: string;
  width?: number;
  height?: number;
  showSpeedMeter?: boolean;
}

const Avatar = ({ id = 1,
    avatarUrl = null,
    isUserAvatar = false,
    name,
    user = null,
    altUrl = '',
    width = 64,
    height = 64,
    showSpeedMeter = false
  }: AvatarInterface): ReactElement => {
  const useCacheOnURL = url => url.replace(/musiversal-cms.s3.eu-west-2.amazonaws.com/, 'dfulleue7uvkm.cloudfront.net');

  const [avatar, setAvatar] = useState(avatarUrl);

  const usagePercent = () => {
    if (user) {
      try {
        if (user?.speed_limit === 0) {
          return '0';
        }
        const { sessions_left, speed_limit } = user;
        const calcPercent = (sessions_left / speed_limit) * 100;
        return `${calcPercent}`;
      } catch (err) {
        return '0';
      }
    }
    return '0';
  };

  const replaceImage = error => {
    const { target } = error;
    target.src = DefaultAvatar;
  };

  useEffect(() => {
    if (isUserAvatar) {
      if (user?.avatar) {
        setAvatar(user.avatar);
      } else {
        setAvatar('../../../../images/default-avatar.png');
      }
    } else {
      setAvatar(avatarUrl);
    }
  }, [avatarUrl]);

  return (
    <>
      <div className={styles.container} data-user_id={id || ''} data-id='avatar'>
        {isUserAvatar ?
          (showSpeedMeter ? (
            <div className={classNames(styles.speedMeter, styles[`percent-${usagePercent()}`])}>
              <div className={styles.circleImage}>
                <img
                  key={id}
                  src={avatar}
                  alt={name}
                  width={59}
                  height={59}
                  loading='eager'
                  onError={replaceImage}
                />
              </div>
            </div>
          ) : (
            <div className={styles.withoutSpeedMeter}>
              <div className={styles.circleImage}>
                <img
                  key={id}
                  src={avatar}
                  alt={name}
                  width={width}
                  height={height}
                  loading='eager'
                  onError={replaceImage}
                />
              </div>
            </div>
          )
        ) : (
          <div className={styles.circleImage}>
            <img
              key={id}
              src={avatar}
              alt={name}
              width={width}
              height={height}
              loading='eager'
              onError={replaceImage}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Avatar;