import React, { useEffect, useState, ReactElement } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RootState } from '../../../../store/store';
import { ModalInterface } from '../../../../interfaces/modal.interface';
import UserService from '../../../../services/user/user.service';
import { localStorageKeys } from '../../../../constants/localStorageKeys.const';
import { getMe } from '../../../../store/actions/user.actions';
import {
  selectOnboardingData
} from '../../../../store/slices/global.slice';
import {
  setIsSelectionModal,
  setIsVideoModal,
  setIsHireProducerModal,
  selectOnboardingModalData
} from '../../../../store/slices/modals.slice';

import { selectUser } from '../../../../store/slices/user.slice';
import ModalWrapper from '../../ModalWrapper/ModalWrapper';
import { ButtonImages } from '@musiversal/musiversal-components';

import styles from './SelectionModal.module.scss';

import {
  headerTitle,
  headerSubtitle,
  linkText,
  message,
  optionsSongwriter
} from './SelectionModal.const';

export enum OptionActions {
  OPEN_VIDEO = 'openVideo',
  OPEN_CALENDLY = 'openCalendly',
  OPEN_HIRE_PRODUCER_MODAL = 'openHireProducerModal',
  OPEN_ARTIST = 'openArtist'
}

export enum userPersonas {
  PROFESSIONAL = 'professional',
  CASUAL = 'casual',
  ASPIRING = 'aspiring'
}

const SelectionModal = ({ isOpen }: ModalInterface): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(selectUser, shallowEqual);
  const onboardingData = useSelector(selectOnboardingData, shallowEqual);
  const onboardingModalData = useSelector(selectOnboardingModalData, shallowEqual);
  const { isSelectionModal } = useSelector((state: RootState) => state.modalsSlice, shallowEqual);
  const [persona, setPersona] = useState(userPersonas.CASUAL);

  useEffect((): void => {
    const fetchPersona = async (): Promise<void> => {
      try {
        const { data } = await UserService.getPersona();
        if (data) {
          setPersona(data?.persona);
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (!user.onboarding) {
      fetchPersona();
    }
  }, [user]);

  const goToLink = () => {
    window.open(onboardingData.starting.calendly_link);
  };

  const openHireProducerModal = () => {
    dispatch(setIsHireProducerModal(true));
    onRequestClose();
  };

  const openHowItWorksVideoModal = () => {
    dispatch(setIsVideoModal(true));
    onRequestClose();
  };

  const openArtist = artistSlug => {
    window.open(`/explore/${artistSlug}`, '_self');
    onRequestClose();
  };

  const selectOnClickFunction = (option): any => {
    if (option.action === OptionActions.OPEN_CALENDLY) {
      onRequestClose();
      return goToLink;
    }
    if (option.action === OptionActions.OPEN_HIRE_PRODUCER_MODAL) {
      onRequestClose();
      return openHireProducerModal;
    }
    if (option.action === OptionActions.OPEN_ARTIST) {
      onRequestClose();
      return openArtist;
    }
    onRequestClose();
    return openHowItWorksVideoModal;
  };

  const onRequestClose = async (): void => {
    // Mark account with onboarding as true so this message won't show automatically every time
    if (!user.onboarding) {
      await UserService.completeOnboarding();
      await dispatch(getMe());
    }
    await dispatch(setIsSelectionModal(false));
    history.push('/explore');
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      isPreventClosing={false}
      onRequestClose={onRequestClose}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>
            {onboardingModalData?.SelectionModal?.title || headerTitle}
          </div>
          <div className={styles.subtitle}>
            {onboardingModalData?.SelectionModal?.subtitle || headerSubtitle}
          </div>
        </div>
        <div className={styles.optionsList}>
          {(onboardingModalData?.SelectionModal[persona] || optionsSongwriter).map((op, idx) => (
            <div key={idx} className={styles.option}>
              <ButtonImages
                key={idx}
                title={op.title}
                description={op.description}
                image={op.image}
                onClick={() => selectOnClickFunction(op)(op.artistSlug)}
              />
            </div>
          ))}
        </div>
        <div className={styles.continue}>
          <div className={styles.link} onClick={onRequestClose}>
            {onboardingModalData?.SelectionModal?.linkText || linkText}
          </div>
          <div className={styles.message}>
            {onboardingModalData?.SelectionModal?.linkSubtext || message}
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default SelectionModal;
