import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { FacebookProvider, LoginButton } from 'react-facebook';
import TagManager from 'react-gtm-module';

import { MixPanelSignUpEvents } from '../../../../enums/mixpanel-events.enum';
import { MixPanelChoseSignUpMethodProperties } from '../../../../enums/mixpanel-properties.enum';
import { SignupMethods } from '../../../../enums/signup-methods.enum';
import { Mixpanel } from '../../../../helpers/mixpanel.helper';
import Icon from '../../Icon/Icon';
import styles from './FacebookLogin.module.scss';

interface FacebookLoginInterface {
  referral_code?: string;
  invitationCode?: string;
  isSignUp?: boolean;
  isJoinWaitlist?: boolean;
  timezone?: any;
  setError: any;
  urlParams?: any;
  extraParams?: any;
  callback: any;
  buttonText?: string;
}

const FacebookLogin = ({
  buttonText,
  referral_code,
  invitationCode,
  isSignUp,
  isJoinWaitlist = false,
  extraParams,
  urlParams,
  setError,
  callback
}: FacebookLoginInterface): ReactElement => {
  const handleResponse = async data => {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v2/auth/facebook/callback`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        data: data,
        referral_code: referral_code,
        invitation_code: invitationCode,
        extraParams,
        isJoinWaitlist,
        query_params: urlParams
      }),
      withCredentials: true
    });
    try {
      const response = await res.json();
      if (response.status === 422) {
        setError('provider', {
          type: 'manual',
          message: 'You need to use a different login provider.'
        });
      }
      try {
        if (isJoinWaitlist) {
          TagManager.dataLayer({
            dataLayer: {
              event: 'waitlister_acquired',
              method: 'facebook',
              user_id: res?.data?.user?.id
            }
          });
        }
      } catch (err) {
        console.error(err);
      }
      // We want to fire it only when user is creating an account
      // if (isSignUp) {
      //   Mixpanel.track(MixPanelSignUpEvents.COMPLETED_SIGN_UP);

      //   TagManager.dataLayer({
      //     dataLayer: {
      //       event: 'sign_up',
      //       method: 'facebook',
      //       user_id: res?.data?.user?.id
      //     }
      //   });
      // } else {
      //   TagManager.dataLayer({
      //     dataLayer: {
      //       event: 'login',
      //       method: 'facebook',
      //       user_id: res?.data?.user?.id
      //     }
      //   });
      // }
      await callback();
    } catch (err) {
      console.error(err);
    }
  };

  const handleError = error => {
    setError({ error });
  };

  // const onButtonClick = () => {
  //   if (!buttonText) {
  //     Mixpanel.track(MixPanelSignUpEvents.CHOSE_SIGN_UP_METHOD, {
  //       [MixPanelChoseSignUpMethodProperties.METHOD]: SignupMethods.FACEBOOK
  //     });
  //   }
  // };

  return (
    // <div className={styles.facebookLoginContainer} onClick={onButtonClick}>
    <div className={styles.facebookLoginContainer}>
      <FacebookProvider appId={process.env.REACT_APP_FACEBOOK_ID}>
        <LoginButton
          className={styles.button}
          scope='email,public_profile'
          onSuccess={handleResponse}
          onError={handleError}
        >
          <div className={styles.buttonIcon}>
            <Icon name='facebook' />
          </div>
          <div className={styles.buttonText}>
            {buttonText ?? `Sign ${isSignUp ? 'up' : 'in'} with Facebook`}
          </div>
          <div className={styles.emptyDiv}></div>
        </LoginButton>
      </FacebookProvider>
    </div>

  );
};

export default FacebookLogin;
