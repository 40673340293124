import cookieCutter from 'cookie-cutter';
import { useFlags } from 'launchdarkly-react-client-sdk';
import TagManager from 'react-gtm-module';
import moment from 'moment-timezone';
import * as queryString from 'query-string';
import React, { ReactElement, useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import XRegExp from 'xregexp';
import { SplitContainer, ListItems, Button, Input } from '@musiversal/musiversal-components';

import Icon from '../../components/Atoms/Icon/Icon';
import FacebookLogin from '../../components/Atoms/v3/FacebookLogin/FacebookLogin';
import GoogleLoginButton from '../../components/Atoms/v3/GoogleLoginButton/GoogleLoginButton';
// import Input from '../../components/Atoms/v3/Input/Input';
import { loginText } from '../../components/Molecules/SignUpFlowNavbar/SignUpFlowNavbar.const';
// import { ButtonSizes } from '../../enums/button-sizes.enum';
import { InputTypes } from '../../enums/input-types.enum';
import { Routes } from '../../enums/routes.enum';
import useIsPartnerGuard from '../../guards/useIsPartnerDashboard.guard';
import { WaitlistDataInterface } from '../../services/auth/auth.interface';
import AuthService from '../../services/auth/auth.service';
import { getOnboardingData } from '../../store/actions/global.actions';
import { getMe } from '../../store/actions/user.actions';
import { selectOnboardingData } from '../../store/slices/global.slice';
import { header, buttonSubmittingText, buttonText, orText, rightColumnTitle } from './join-waitlist.const';
import styles from './JoinWaitlist.module.scss';

// import { useCheckCodeHook } from './useCheckCode.hook';

const JoinWaitlist = (): ReactElement => {
  const { loginWithFacebook, loginWithGoogle } = useFlags();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    watch,
    setValue
  } = useForm();
  const [submitting, setSubmitting] = useState(false);
  const location = useLocation();
  const [timezone, setTimezone] = useState(null);
  const urlParams = useRef(queryString.parse(location.search));
  const history = useHistory();
  const redirectRoute = '/explore';
  const dispatch = useDispatch();
  const onboardingData = useSelector(selectOnboardingData, shallowEqual);

  // const { isLoading, referral, referralError, referralFirstName } = useCheckCodeHook({ referralCode, setValue });

  useEffect((): void => {
    const loadOnboardingData = async (): Promise<void> => {
      await dispatch(getOnboardingData());
    };

    const init = async (): Promise<void> => {
      await loadOnboardingData();
    };

    init().finally();
  }, [dispatch]);

  watch(['email', 'name']);

  useEffect((): void => {
    const params = queryString.parse(location.search);
    setValue('email', params?.email ?? '');
    // if (!params?.invitation_code) {
    //   window.location.href = 'https://www.musiversal.com/unlimited';
    // } else {
    history.push(Routes.JOIN_WAITLIST);
    // }
  }, []);

  useIsPartnerGuard();

  const redirectToPrivateArea = () => {
    setSubmitting(false);
    history.push(redirectRoute);
  };

  const onSubmit = async data => {
    setSubmitting(true);

    const joinWaitlistData = {
      user: {
        name: data.name,
        email: data.email?.toLowerCase(),
        timezone,
        password: data.password
      },
      method: 'official',
      query_params: urlParams.current
    } as WaitlistDataInterface;

    try {
      await AuthService.logout();
      const res = await AuthService.joinWaitlist(joinWaitlistData);
      if (res.status === 200) {
        await dispatch(getMe());
        localStorage.setItem('timezone', timezone);
        cookieCutter.set('showOnboarding', true, { httpOnly: true });
        TagManager.dataLayer({
          dataLayer: {
            event: 'waitlister_acquired',
            method: 'email',
            timezone: timezone,
            user_id: res?.data?.user?.id
          }
        });
        await redirectToPrivateArea();
      }
    } catch (err) {
      if (err.response?.status === 401) {
        setSubmitting(false);

        setError('email', {
          type: 'manual',
          message: 'That email already exists in Musiversal, please login instead.'
        });
      } else {
        setError('server', {
          type: 'manual',
          message: 'We have some issues connecting to our servers. Please try again later.'
        });
      }
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const tz = moment.tz.guess();
    setTimezone(tz);
  }, []);

  return (
    <SplitContainer
      leftColumn={
        <div className={styles.container}>
          <div className={styles.logo}>
            <Icon name={'musiversal-logo'} />
          </div>
          <div className={styles.signupHeader}>
            <h1 className={styles.title}>{header}</h1>
            <div className={styles.loginParagraph}>
              <div className={styles.loginMessage}>Already have an account?</div>
              <div className={styles.loginLink}>
                <Link to={'/login'}>
                  <span className={styles.login}>{loginText}</span>
                </Link>
              </div>
            </div>
          </div>

          <div className={styles.form}>
            <div className={styles.socialLogins}>
              {loginWithFacebook && (
                <FacebookLogin
                  timezone={timezone}
                  callback={redirectToPrivateArea}
                  setError={setError}
                  isSignUp={false}
                  urlParams={urlParams.current}
                  isJoinWaitlist={true}
                  extraParams={urlParams.current}
                  buttonText='Continue with Facebook'
                />
              )}
              {loginWithGoogle && (
                <GoogleLoginButton
                  timezone={timezone}
                  callback={redirectToPrivateArea}
                  urlParams={urlParams.current}
                  isJoinWaitlist={true}
                  setError={setError}
                />
              )}
            </div>
            {(loginWithFacebook || loginWithGoogle) && (
              <div className={styles.divider}>
                <div className={styles.divLine} />
                <div className={styles.orText}>{orText}</div>
                <div className={styles.divLine} />
              </div>
            )}

            <form onSubmit={handleSubmit(onSubmit)} className={styles.formInner}>
              <Input
                placeholder={'Email Address'}
                type={InputTypes.EMAIL}
                name={'email'}
                id={'email'}
                registerRef={register('email', {
                  required: 'Required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'Invalid email address'
                  }
                })}
                errors={[errors.email?.message]}
                clearErrors={() => clearErrors()}
              />
              <Input
                placeholder={'Full Name'}
                type={InputTypes.TEXT}
                name={'name'}
                id={'name'}
                registerRef={register('name', {
                  required: 'Required',
                  pattern: {
                    // eslint-disable-next-line new-cap
                    value: XRegExp("^(?!\\s)([\\pL'-]+\\s)+([\\pL'-])+$"),
                    message: 'Your first name and last name are required'
                  }
                })}
                errors={[errors.name?.message]}
                clearErrors={() => clearErrors()}
              />
              <Input
                placeholder={'Password'}
                type={InputTypes.PASSWORD}
                name={'password'}
                id={'password'}
                registerRef={register('password', {
                  required: 'Required',
                  minLength: {
                    value: 8,
                    message: 'Password must have at least 8 characters'
                  }
                })}
                errors={[errors.password?.message, errors.provider?.message, errors.server?.message]}
                clearErrors={() => clearErrors()}
              />
              <div className={styles.button}>
                <Button
                  isSubmit
                  disabled={submitting}
                  width={'100%'}
                  // buttonSize={ButtonSizes.LARGE}
                  buttonText={submitting ? buttonSubmittingText : buttonText}
                />
              </div>
            </form>

            <p className={styles.disclaimer}>
              Joining the Musiversal waitlist means you agree to the{' '}
              <a href='https://www.musiversal.com/privacy-policy' target='_blank' rel='noreferrer'>
                Privacy Policy
              </a>{' '}
              and{' '}
              <a href='https://www.musiversal.com/terms-of-use' target='_blank' rel='noreferrer'>
                Terms of Use
              </a>
              .
            </p>
          </div>
        </div>
      }
      rightColumn={
        <div className={styles.rightColumnContainer}>
          <ListItems title={rightColumnTitle} bullets={onboardingData?.joinWaitlist?.bullets ?? []} />
        </div>
      }
    />
  );
};

export default JoinWaitlist;
