import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';

import InputError from '../../../../Atoms/InputError/InputError';
import styles from '../../BookSessionForm.module.scss';
import { genreOptions } from '../../consts/genreOptions.const';
import { shallowEqual, useSelector } from 'react-redux';
import { selectCurrentlyBookingArtistConfig } from '../../../../../store/slices/musicians.slice';
import CustomSelect, { DropdownArrow } from '../../../../Atoms/Select/Select';
import classNames from 'classnames';
import { selectSession, selectTracks } from '../../../../../store/slices/sessions.slice';
import { getLabelValueObjectHelper } from '../../../../../helpers/get-label-value-object.helper';
import { BookSessionTabs } from '../../../../../enums/book-session-tabs.enum';

export default function TrackDetailsForm({
                                           setStep,
                                           children,
                                           watch,
                                           register,
                                           control,
                                           errors,
                                           setValue,
                                           handleSubmit,
                                           getValues,
                                           clearErrors
                                         }) {
  const session = useSelector(selectSession, shallowEqual);
  const tracks = useSelector(selectTracks, shallowEqual);
  const artistConfig = useSelector(selectCurrentlyBookingArtistConfig, shallowEqual);
  const [trackTitleValue, setTrackTitleValue] = useState();

  const { form: formToggles } = (artistConfig.services.servicesList.find(({ value }) =>
    value === getValues().service || value === session?.session_info?.options) || {});

  const watchTrackTitle = watch('track_title');

  const [options, setOptions] = useState(tracks.filter(track => track.title).map(t => ({
    value: t.id,
    label: t.title
  })));

  useEffect(() => {
    if (!watchTrackTitle?.value || session?.track_id === watchTrackTitle?.value) {
      return;
    }
    const selectedTrack = tracks.filter(t => t.id === watchTrackTitle.value)[0];
    if (!selectedTrack) {
      return;
    }
    const assignedGenre = genreOptions.filter(g => g.value === selectedTrack.track_genre)[0];
    if (assignedGenre) {
      setValue('track_genre', { label: assignedGenre.value, value: assignedGenre.value }, { shouldValidate: true });
    }
    if (selectedTrack.reference_track) {
      setValue('reference_track', selectedTrack.reference_track, { shouldValidate: true });
    }
    if (selectedTrack.meter) {
      setValue('meter', selectedTrack.meter, { shouldValidate: true });
    }
    if (selectedTrack.bpm) {
      setValue('bpm', selectedTrack.bpm, { shouldValidate: true });
    }
    if (selectedTrack.sampleRate) {
      setValue('sampleRate', getLabelValueObjectHelper(selectedTrack.sampleRate, artistConfig.sampleRates), { shouldValidate: true });
    }
  }, [artistConfig.sampleRates, session?.track?.track_id, session?.track_id, setValue, tracks, watchTrackTitle]);

  const moveToNextStep = () => {
    console.log('moveToNextStep');
    if (Object.values(errors).length) {
      console.log(Object.values(errors));
      return;
    }
    setStep?.(BookSessionTabs.SESSION_DETAILS)();
  };

  const handleBlur = () => {
    const label = trackTitleValue?.trim() || '';
    const optionExists = options.find(opt => opt.label === label);

    if (!label) {
      return;
    }

    if (optionExists) {
      setTrackTitleValue(optionExists.label);
      setValue('track_title', optionExists);
      return;
    }

    const option = { label, value: label, addedOnBlur: true };

    setOptions([...options, option]);
    setValue('track_title', option);
    setTrackTitleValue('');

    setTimeout(() => clearErrors(['track_title']));
  };

  const onInputChange = (textInput, { action }) => {
    if (action === 'set-value') {
      setTrackTitleValue('');
    }
    if (action === 'input-change') {
      setTrackTitleValue(textInput);
    }
    if (action === 'input-blur') {
      handleBlur();
    }
  };

  return (
    <form onSubmit={handleSubmit(moveToNextStep)}>
      <label className={classNames(styles.label, styles.mbSmall)}>
        Track Name
        <Controller
          name='track_title'
          control={control}
          rules={{
            validate: {
              isRequired: v => !!v?.value,
              isTooLong: v => v?.label.length < 255
            }
          }}
          render={({ field }) => (
            <CreatableSelect
              isClearable
              classNamePrefix='react-select'
              options={options}
              onInputChange={onInputChange}
              onClear
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: () => <DropdownArrow />
              }}
              {...field}
              styles={{
                clearIndicator: provided =>
                  ({ ...provided, marginRight: '40px', cursor: 'pointer', color: '#9CA3AF' })
              }}
            />
          )}
        />
        {errors.track_title?.type === 'isRequired' && (
          <InputError message='You need to provide a track name' />
        )}
        {errors.track_title?.type === 'isTooLong' && (
          <InputError message='Track Name is too long' />
        )}
      </label>

      {(formToggles.track_genre ?? true) && <CustomSelect
        control={control}
        label={'Track Genre'}
        name={'track_genre'}
        options={genreOptions}
        containerClassName={styles.mbSmall}
        labelClassName={styles.label}
        className={styles.control}
      />}

      {(formToggles.reference_track ?? true) && <label className={classNames(styles.label, styles.mbSmall)}>
        Reference Track
        <input
          className={styles.input}
          type='text'
          maxLength={254}
          placeholder='https://open.spotify.com/track/6n22mF64M1TQFc01aXtyhc?si=d206028c819b4bd1'
          {...register('reference_track', {
            pattern: {
              value: /^((https?):\/\/)?[-a-zA-Z0-9@:%._+~#=]{2,255}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/,
              message: 'Seems like you are entering something that is not a link. You can leave comments in the deliverables section.'
            }
          })}
        />
        {errors.reference_track && <InputError message={errors.reference_track.message} />}
      </label>}

      <div className={styles.sessionForm__row}>
        {(formToggles.meter ?? true) && <label className={classNames(styles.label, styles.mbSmall)}>
          Meter
          <input
            className={styles.input}
            id='meter'
            name='meter'
            type='text'
            maxLength={254}
            placeholder='e.g. 3/4'
            {...register('meter')}
          />
          {errors.meter && <InputError message={errors.meter.message} />}
        </label>}

        {(formToggles.bpm ?? true) && <label className={classNames(styles.label, styles.mbSmall)}>
          BPM
          <input
            className={styles.input}
            id='bpm'
            name='bpm'
            type='text'
            maxLength={254}
            placeholder='e.g. Quarter note 120'
            {...register('bpm')}
          />
          {errors.bpm && <InputError message={errors.bpm.message} />}
        </label>}
      </div>

      {(formToggles.sample_rate ?? true) && <CustomSelect
        control={control}
        label={'Sample Rate'}
        name={'sampleRate'}
        options={artistConfig.sampleRates}
        containerClassName={styles.sampleRateContainer}
        labelClassName={styles.label}
        className={styles.control}
        labelElement={<p className={styles.sampleRate}>Sample Rate <span
          className={styles.label}>Bit Depth 24-bit</span>
        </p>}
      />}

      {errors.server && <InputError message={errors.server.message} />}
      {children}
    </form>
  );
}
