import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ModalInterface } from '../../../../interfaces/modal.interface';
import { ModalTypes } from '../../../../enums/modal-types.enum';
import ModalWrapper from '../../ModalWrapper/ModalWrapper';
import Button from '../../../Atoms/v3/Button/Button';
import Icon from '../../../Atoms/Icon/Icon';
import { setIsShowSpeedLimitModal } from '../../../../store/slices/modals.slice';
import { selectUser } from '../../../../store/slices/user.slice';
import styles from './SpeedLimitModal.module.scss';

const MAX_SESSIONS_ALLOWED = 4;
const SpeedLimitModal = ({ isOpen }: ModalInterface): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { sessions_limit: sessionsLimit, sessions_used: sessionsUsed } = useSelector(selectUser, shallowEqual) || {};

  const closeSpeedLimitModal = () => {
    dispatch(setIsShowSpeedLimitModal(false));
  };

  const goToSessionsArea = () => {
    history.push('/sessions');
    dispatch(setIsShowSpeedLimitModal(false));
  };

  return (
    <>
      <ModalWrapper isOpen={isOpen} modalType={ModalTypes.DIALOG} onRequestClose={closeSpeedLimitModal}>
        <div className={styles.container}>
          <div className={styles.ilustration}>
            <Icon name='pause-ilustration' />
          </div>
          <h1 className={styles.title}>On hold</h1>
          <div className={styles.description}>
            You have {sessionsUsed} active sessions at the moment. Once you finish one, you can book another.
          </div>
          <div className={styles.button}>
            <Button
              onClick={() => goToSessionsArea()}
              className={styles.getFullAccessButton}
              width={'100%'}
              buttonText={'Check your sessions'}
            />
          </div>
        </div>
      </ModalWrapper>
    </>
  );
};

export default SpeedLimitModal;
