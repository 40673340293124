export enum UserStatuses {
  SIGNUP = 'signup',
  WAITLISTER = 'waitlister',
  INVITED = 'invited',
  STAFF = 'staff',
  UNSUBSCRIBED = 'unsubscribed',
  UNLIMITED = 'unlimited',
  CANCELLED = 'cancelled',
  PENDING_CANCELLATION = 'pending_cancellation'
}
