import { ListRadioOptions, RadioOption, ButtonImages, Button } from '@musiversal/musiversal-components';
import { on } from 'events';
import { set } from 'lodash';
import moment from 'moment-timezone';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Control, useForm, UseFormRegister, UseFormWatch } from 'react-hook-form';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { components } from 'react-select';

import { ModalTypes } from '../../../../enums/modal-types.enum';
import { ModalInterface } from '../../../../interfaces/modal.interface';
import { FinishAccountSetupDataInterface } from '../../../../services/user/user.interface';
import UserService from '../../../../services/user/user.service';
import { getOnboardingModalData } from '../../../../store/actions/modals.actions';
import { getMe } from '../../../../store/actions/user.actions';
import {
  setIsShowOnboardingModal,
  selectOnboardingModalData,
  setIsSelectionModal
} from '../../../../store/slices/modals.slice';
import { selectUser, setUser } from '../../../../store/slices/user.slice';
import ModalWrapper from '../../ModalWrapper/ModalWrapper';
import {
  headerTitle,
  headerSubtitle,
  linkText,
  message,
  options,
  optionsSongwriter,
  professional,
  aspiring,
  casual,
  title,
  body,
  cta
} from './OnboardingModal.const';
import styles from './OnboardingModal.module.scss';

export enum OptionActions {
  OPEN_VIDEO = 'openVideo',
  OPEN_CALENDLY = 'openCalendly',
  OPEN_HIRE_PRODUCER_MODAL = 'openHireProducerModal',
  OPEN_ARTIST = 'openArtist'
}

export enum userPersonas {
  PROFESSIONAL = 'professional',
  CASUAL = 'casual',
  ASPIRING = 'aspiring'
}

const OnboardingModal = ({ isOpen }: ModalInterface): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { control, getValues, watch, register, setValue, handleSubmit } = useForm({
    defaultValues: {
      persona_type: userPersonas.CASUAL
    }
  });

  const user = useSelector(selectUser, shallowEqual);
  const onboardingModalData = useSelector(selectOnboardingModalData, shallowEqual);

  const [persona, setPersona] = useState(userPersonas.CASUAL);
  const [hasSubmittedSurvey, setHasSubmittedSurvey] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect((): void => {
    const loadOnboardingData = async (): Promise<void> => {
      await dispatch(getOnboardingModalData());
    };

    const init = async (): Promise<void> => {
      await loadOnboardingData();
      setLoading(false);
    };

    init().finally();
  }, [dispatch]);

  const openHowItWorksVideoModal = () => {
    // dispatch(setIsVideoModal(true));
    onRequestClose();
  };

  const openArtist = artistSlug => {
    window.open(`/explore/${artistSlug}`, '_self');
    onRequestClose();
  };

  const selectOnClickFunction = (option): any => {
    if (option.action === OptionActions.OPEN_ARTIST) {
      onRequestClose();
      return openArtist;
    }
    onRequestClose();
    return openHowItWorksVideoModal;
  };

  const submitPersonaSurvey = async data => {
    const formValues = getValues();
    if (!formValues.persona_type) {
      formValues.persona_type = userPersonas.CASUAL;
    }
    const formData: FinishAccountSetupDataInterface = {
      persona_type: formValues.persona_type
    };

    try {
      const resp = await UserService.finishAccountSetup(formData);
      await dispatch(getMe());
      setPersona(formValues.persona_type);
      setHasSubmittedSurvey(true);
      await dispatch(setIsShowOnboardingModal(false));
      await dispatch(setIsSelectionModal(true));
      history.push('/explore');
      //  sendToast({ message: 'Your account is ready!', toastType: ToastTypes.SUCCESS });
      //  redirectToExplore();
    } catch (e) {
      console.error(e);
      //  sendToast({ message: e, toastType: ToastTypes.DANGER });
    }
  };

  const onRequestClose = async (): void => {
    // Mark account with onboarding as true so this message won't show automatically every time
    if (!user.onboarding) {
      await UserService.completeOnboarding();
    }
    await dispatch(setIsShowOnboardingModal(false));
  };

  return (
    !loading && (
      <ModalWrapper
        isOpen={isOpen}
        isPreventClosing={!hasSubmittedSurvey}
        modalType={ModalTypes.DEFAULT}
        onRequestClose={onRequestClose}
      >
        {!hasSubmittedSurvey ? (
          <div className={styles.container}>
            <div className={styles.titleBody}>
              <div className={styles.title}>{onboardingModalData?.UserPersonaSurvey?.title}</div>
              <div className={styles.body}>{onboardingModalData?.UserPersonaSurvey?.subtitle}</div>
            </div>
            <ListRadioOptions>
              {onboardingModalData?.UserPersonaSurvey?.surveyOptions?.map(option => (
                <RadioOption
                  required
                  key={option.id}
                  defaultChecked={persona === option.id}
                  name='persona_type'
                  id={option.id}
                  value={option.value}
                  label={option.description}
                  control={control}
                  onClick={() => setPersona(option.value)}
                />
              ))}
            </ListRadioOptions>
            <div className={styles.button}>
              <Button buttonText={cta} onClick={submitPersonaSurvey} />
            </div>
          </div>
        ) : (
          <div className={styles.containerSelection}>
            <div className={styles.header}>
              <div className={styles.title}>{onboardingModalData?.SelectionModal.title}</div>
              <div className={styles.subtitle}>{onboardingModalData?.SelectionModal.subtitle}</div>
            </div>
            <div className={styles.optionsList}>
              {onboardingModalData?.SelectionModal[persona].map((op, idx) => (
                <div key={idx} className={styles.option}>
                  <ButtonImages
                    key={idx}
                    title={op.title}
                    description={op.description}
                    image={op.image}
                    onClick={() => selectOnClickFunction(op)(op.artistSlug)}
                  />
                </div>
              ))}
            </div>
            <div className={styles.continue}>
              <div className={styles.link} onClick={onRequestClose}>
                {onboardingModalData?.SelectionModal?.linkText}
              </div>
              <div className={styles.message}>{onboardingModalData?.SelectionModal?.linkSubtext}</div>
            </div>
          </div>
        )}
      </ModalWrapper>
    )
  );
};

export default OnboardingModal;
