import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ModalInterface } from '../../../../interfaces/modal.interface';
import { ModalTypes } from '../../../../enums/modal-types.enum';
import ModalWrapper from '../../ModalWrapper/ModalWrapper';
import Button from '../../../Atoms/v3/Button/Button';
import UserService from '../../../../services/user/user.service';
import { selectUser } from '../../../../store/slices/user.slice';
import { setIsShowRejoinWaitlistModal } from '../../../../store/slices/modals.slice';
import { hideToast, showToast } from '../../../../store/slices/toast.slice';
import { UserStatuses } from '../../../../enums/user-statuses.enum';
import styles from './RejoinWaitlistModal.module.scss';

const RejoinWaitlistModal = ({ isOpen }: ModalInterface): ReactElement => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser, shallowEqual);

  const closeRejoinWaitlistModal = () => {
    dispatch(setIsShowRejoinWaitlistModal(false));
  };

  const sendToast = (message, toastType, delay = 4000) => {
    const payload = {
      message,
      toastType
    };
    dispatch(showToast(payload));
    setTimeout(() => {
      dispatch(hideToast());
    }, delay);
  };

  const rejoinWaitlist = async () => {
    const response = await UserService.rejoinWaitlistNotification({});
    sendToast(response?.data?.msg, 'success');
    dispatch(setIsShowRejoinWaitlistModal(false));
  };

  return (
    <>
      <ModalWrapper isOpen={isOpen} modalType={ModalTypes.DIALOG} onRequestClose={closeRejoinWaitlistModal}>
        <div className={styles.container}>
          <h1 className={styles.title}>{user.status === UserStatuses.UNSUBSCRIBED ? 'Invitation expired' : 'Subscription cancelled'}</h1>
          <div className={styles.description}>
          {user.status === UserStatuses.UNSUBSCRIBED ? 'To subscribe, please rejoin the waitlist' : 'You need to subscribe to be able to book sessions'}
          </div>
          <div className={styles.button}>
            <Button
              onClick={rejoinWaitlist}
              className={styles.getFullAccessButton}
              width={'100%'}
              buttonText={'Join waitlist'}
            />
          </div>
        </div>
      </ModalWrapper>
    </>
  );
};

export default RejoinWaitlistModal;
